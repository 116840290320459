import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {keycloakInstanceCreated, keycloakService, setKeycloakAuthenticationInDevelopmentMode} from '@ndw/react-keycloak-authentication';
import {loadReleaseNotesData} from '../../services/ViewerApiService';
import {viewerApiServiceReceivedReleaseNotesData} from '../../services/ViewerApiService/actions/releaseNotesData';
import {mapSceneReleaseNotesSetNewReleaseNotes} from '../../scenes/MapScene/actions/reducers/releaseNotes';

const loadReleaseNotesDataOnKeycloakStarted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            keycloakInstanceCreated,
            setKeycloakAuthenticationInDevelopmentMode
        ])),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => loadReleaseNotesData())
            ))
    );

const setNewReleaseNotesOnReceivedReleaseNotesData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedReleaseNotesData)),
        map(({payload: {data}}) => mapSceneReleaseNotesSetNewReleaseNotes(data))
    );

const releaseNotesEpics: Epic = combineEpics(
    loadReleaseNotesDataOnKeycloakStarted,
    setNewReleaseNotesOnReceivedReleaseNotesData
);

export default releaseNotesEpics;
