import React from 'react';
import {Props} from './Props';
import {css} from 'aphrodite/no-important';
import styles from './styles';

const ReleaseNotesPane: React.FunctionComponent<Props> = ({releaseNotes}) => {

    return <>
        {releaseNotes.map(({version, title, changes}) => <React.Fragment key={title}>
            <div key={version}>
                <h2 className={css(styles.header)}>{title}</h2>
                <ul className={css(styles.list)}>
                    {changes.map((item: string, index: number) => <li key={index}>{item}</li>)}
                </ul>
            </div>
        </React.Fragment>)}
    </>;
};

export default ReleaseNotesPane;
