import {SearchFeature} from './SearchFeature';
import {FeatureCollection} from 'geojson';
import {AnySourceData} from 'mapbox-gl';

export type DATA_SOURCE_STATUS = 'DEACTIVATED' | 'LOADING_DATA' | 'UPDATE_OUT_OF_SYNC' | 'ACTIVE';
export const DATA_SOURCE_STATUS_DEACTIVATED: DATA_SOURCE_STATUS = 'DEACTIVATED';
export const DATA_SOURCE_STATUS_LOADING_DATA: DATA_SOURCE_STATUS = 'LOADING_DATA';
export const DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC: DATA_SOURCE_STATUS = 'UPDATE_OUT_OF_SYNC';
export const DATA_SOURCE_STATUS_ACTIVE: DATA_SOURCE_STATUS = 'ACTIVE';

interface BaseDataSource<DataType> extends StreamableDataSource {
    id: string;
    name: string;
    loadBaseData: boolean;
    refreshUpdate: boolean;
    sourceLayer: string;
    dataLoadMethod: string;
    updateInterval: number;
    sourceConfiguration: AnySourceData;
    layerFilteredMapData: DataType | null;
    layerMapData: DataType | null;
    isActive: boolean;
    status: DATA_SOURCE_STATUS;
    searchProperty: string;
    searchFeatures: SearchFeature[];
    websocketReload: boolean;
    colorBlindSupported: boolean;
    currentIdentifier: number | null;
    sourceUpdateIdentifier: number | null;
    lastUpdateReceived: number | null;
    streamable: boolean;
}

interface StreamableDataSource {
    websocketUrl: string;
    lastSnapshotUpdateTimestamp: number | null;
    incrementStreamName: string | null;
}

export interface DataSourceGeoJson extends BaseDataSource<FeatureCollection> {
    dataLoadMethod: 'GeoJSON';
}

export interface DataSourceVectorTiles extends BaseDataSource<Record<string, unknown>> {
    dataLoadMethod: 'VectorTiles';
}

export type DataSource = DataSourceGeoJson | DataSourceVectorTiles;
