import React, {ReactElement} from 'react';
import {Props} from './Props';

class DialogDummy extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Naam</th>
                        <td>{properties.name}</td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>;
    }

}

export default DialogDummy;
