import {Props} from './Props';
import React, {useMemo, useState} from 'react';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {Point} from 'geojson';
import {
    GeoJsonPropertiesIncidentManagementClustered
} from '../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesIncidentManagementClustered';
import TabBar from '../../../../../../components/TabBar';
import {TabBarItem} from '../../../../../../components/TabBar/Props';
import {MapboxGeoJSONFeature} from 'mapbox-gl';
import DialogIncidentManagementClusteredSubIncident from './components/DialogIncidentManagementClusteredSubIncident';

export const DialogIncidentManagementClustered: React.FunctionComponent<Props> = (props) => {
    const coordinates = useMemo(() => (JSON.parse(props.properties.geometry) as Point).coordinates.reverse().join(', '), [props]);
    const subSituationRecords = useMemo(() => JSON.parse(props.properties.subSituationRecords) as (MapboxGeoJSONFeature & {properties: GeoJsonPropertiesIncidentManagementClustered})[], [props]);
    const subSituationRecordsProperties = subSituationRecords.map(subSituationRecord => subSituationRecord.properties);
    const [currentTab, changeCurrentActiveTab] = useState<GeoJsonPropertiesIncidentManagementClustered>(subSituationRecordsProperties[0]);
    const tabBarItems: TabBarItem<string>[] = subSituationRecordsProperties.map((situationRecordProperties, index) => ({
        id: situationRecordProperties.id,
        name: situationRecordProperties.imSupplier || `bron-${index + 1}`
    }));

    return <>
        <table>
            <tbody>
                {props.showRecordIds && <tr>
                    <th>ID</th>
                    <td>{props.properties.id}</td>
                </tr>}
                <tr>
                    <th>Type</th>
                    <td>{props.properties.incidentType}</td>
                </tr>
                <tr>
                    <th>Data bron</th>
                    <td>{props.properties.imSupplier}</td>
                </tr>
                <tr>
                    <th>Aanmeld tijd</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.observationTime}/>
                    </td>
                </tr>
                <tr>
                    <th>Laatste update tijd</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.lastUpdatedTime}/>
                    </td>
                </tr>
                <tr>
                    <th>Start tijd</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.overallStartTime}/>
                    </td>
                </tr>
                <tr>
                    <th>Staat het er nog?</th>
                    <td>
                        {props.properties.levelOfNonPersistence}
                    </td>
                </tr>
                <tr>
                    <th>Bepalingsmethode</th>
                    <td>
                        {props.properties.levelOfNonPersistenceDetType}
                    </td>
                </tr>
                <tr>
                    <th>Kans op gebeurtenis</th>
                    <td>
                        {props.properties.levelOfPersistence}
                    </td>
                </tr>
                <tr>
                    <th>Bepalingsmethode gebeurtenis</th>
                    <td>
                        {props.properties.persistenceDeterminationType}
                    </td>
                </tr>
                <tr>
                    <th>Incident Beveiligd</th>
                    <td>
                        {props.properties.incidentProtected ? 'Ja':'Nee'}
                    </td>
                </tr>
                <tr>
                    <th>Incident bekend bij VC</th>
                    <td>
                        {props.properties.incidentKnownByTmc ? 'Ja':'Nee'}
                    </td>
                </tr>
                <tr>
                    <th>Incident bekend bij VC sinds</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.incidentKnownByTmcSince}/>
                    </td>
                </tr>
                <tr>
                    <th>Coördinaten</th>
                    <td>{coordinates}</td>
                </tr>
            </tbody>
        </table>
        <div>
            <TabBar<string>
                tabBarItems={tabBarItems}
                activeId={currentTab.id}
                onClickedOnTab={(id) => changeCurrentActiveTab(subSituationRecordsProperties.filter(srp => srp.id === id)[0])}
            />
        </div>
        <DialogIncidentManagementClusteredSubIncident coordinates={coordinates} properties={currentTab} />
    </>;
};
