import {RootState} from '../../reducer';
import {UrlSettings} from '../../interfaces/UrlSettings';

import {SortingDirection, SortingValue} from '../../interfaces/TrafficInformation';

class UrlService {

    static getInstance(): UrlService {

        if (!this.instance) {
            this.instance = new UrlService();
        }
        return this.instance;
    }

    private static instance: UrlService | null = null;

    private currentUrlObject: UrlSettings;

    private constructor() {
        this.currentUrlObject = {};
    }

    saveChangeToUrl(state: RootState): void {
        this.updateSettingsObject(state);
        this.setCurrentUrlObjectInUrl();
    }

    getStringValueFromUrl(name: keyof UrlSettings, fallback: string): string {
        const re = new RegExp(`[#&]${name}=([^&]+)(&|$)`);
        const matchHash = re.exec(window.location.hash);

        if (matchHash?.length) {
            return matchHash[1];
        }

        return fallback;
    }

    getNumericValueFromUrl(name: keyof UrlSettings, fallback: number): number {
        const re = new RegExp(`[#&]${name}=([^&]+)(&|$)`);
        const matchHash = re.exec(window.location.hash);

        if (matchHash?.length) {
            const value = matchHash[1];
            if (!isNaN(parseFloat(value))) {
                return parseFloat(value);
            }
        }

        return fallback;
    }

    getBooleanValueFromUrl(name: keyof UrlSettings, fallback: boolean): boolean {
        const re = new RegExp(`[#&]${name}=([^&]+)(&|$)`);
        const matchHash = re.exec(window.location.hash);

        if (matchHash?.length) {
            const value = matchHash[1];
            if (!isNaN(parseInt(value, 10))) {
                return parseInt(value, 10) === 1;
            }
        }

        return fallback;
    }

    getObjectValueFromUrl<T>(name: keyof UrlSettings, fallback: T): T {
        const re = new RegExp(`[#&]${name}=([^&]+)(&|$)`);
        const matchHash = re.exec(window.location.hash);

        if (matchHash?.length) {
            try {
                return JSON.parse(decodeURIComponent(matchHash[1])) as T;
            } catch (error) {
                return fallback;
            }
        }

        return fallback;
    }

    private updateSettingsObject(state: RootState) {

        const newUrlObject: UrlSettings = {};

        const activeLayers: string[] = [];
        Object.keys(state.mapScene.mapboxLayers.layers).forEach((key: string) => {
            const mapLayer = state.mapScene.mapboxLayers.layers[key];
            if (mapLayer.isActive) {
                activeLayers.push(mapLayer.id);
            }
        });

        if (activeLayers.length) {
            newUrlObject.activeLayers = activeLayers;
        }
        if (state.mapScene.general.visibleTable) {
            newUrlObject.currentOverviewTable = state.mapScene.general.visibleTable.id;
        }
        if (state.mapScene.general.visibleTableSource) {
            newUrlObject.currentOverviewTableSource = state.mapScene.general.visibleTableSource.sourceType;
        }
        newUrlObject.mapFilterWazeAlertNdwKnown = state.mapScene.filterAndSearch.mapFilters.wazeAlertNdwKnown ? 1 : 0;
        newUrlObject.mapFilterWazeAlertStatusSet = state.mapScene.filterAndSearch.mapFilters.wazeAlertStatusSet ? 1 : 0;
        newUrlObject.mapFilterDfineRvmNetworkOnly = state.mapScene.filterAndSearch.mapFilters.dFineOnlyRvmNetwork ? 1 : 0;
        newUrlObject.mapFilterFdVerifiedTrafficJams = state.mapScene.filterAndSearch.mapFilters.fdVerifiedTrafficJams ? 1 : 0;
        newUrlObject.mapSettingTravelTimeFcdOffset = state.mapScene.general.mapSettings.travelTimeFcdOffset || 0;
        newUrlObject.showRecordIds = state.userSettings.showRecordId ? 1 : 0;
        newUrlObject.showTrafficJamIcons = state.userSettings.showTrafficJamIcons ? 1 : 0;
        newUrlObject.latitude = state.mapScene.general.viewport.latitude;
        newUrlObject.longitude = state.mapScene.general.viewport.longitude;
        newUrlObject.theme = state.userSettings.theme.index;
        if (state.userSettings.currentTrafficCenter) {
            newUrlObject.trafficCenter = state.userSettings.currentTrafficCenter.key;
        }
        newUrlObject.zoom = state.mapScene.general.viewport.zoom;
        newUrlObject.prefixFilters = JSON.stringify(state.mapScene.filterAndSearch.mapFilters.prefixFilter);
        newUrlObject.mapMovementAvailable = state.mapScene.general.mapMovementAvailable ? 1 : 0;
        newUrlObject.sortTrafficMessagesByRoadName = state.mapScene.trafficInformation.sortTrafficMessagesByValue === SortingValue.byRoadName ? 1 : 0;
        newUrlObject.sortTrafficMessagesInAscendingOrder = state.mapScene.trafficInformation.updateTrafficMessagesSortingDirection === SortingDirection.ascending ? 1 : 0;
        newUrlObject.mapFilterRomoBySize = state.mapScene.filterAndSearch.mapFilters.romoSD.sizeFilter;
        newUrlObject.mapFilterRomoBySizeDelta = state.mapScene.filterAndSearch.mapFilters.romoSD.sizeDeltaFilter;
        newUrlObject.mapFilterRomoByJerk = state.mapScene.filterAndSearch.mapFilters.romoSD.jerkFilter;
        newUrlObject.mapFilterRomoByJerkDelta = state.mapScene.filterAndSearch.mapFilters.romoSD.jerkDeltaFilter;
        newUrlObject.mapFilterRomoByTime = state.mapScene.filterAndSearch.mapFilters.romoSD.timeFilter;
        newUrlObject.mapFilterRomoByRoadOperator = state.mapScene.filterAndSearch.mapFilters.romo.roadOperatorId;
        newUrlObject.mapFilterRomoHWSelectedTypes = JSON.stringify(state.mapScene.filterAndSearch.mapFilters.romoHW.selectedTypes);
        this.currentUrlObject = newUrlObject;
    }

    private setCurrentUrlObjectInUrl() {

        const url = Object
            .keys(this.currentUrlObject)
            .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(this.currentUrlObject[k] as unknown as string))
            .join('&');

        window.history.pushState(null, document.title, `#${url}`);
    }
}

export default UrlService;
