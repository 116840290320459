import {
    keycloakAuthenticationEpics,
    keycloakService,
    setKeycloakAuthenticationInDevelopmentMode
} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {viewerApplicationBooted} from './actions';
import {ENVIRONMENT} from './applicationContext';
import applicationEpics from './epics/applicationEpics';
import configurationEpics from './epics/configurationEpics';
import dialogEpics from './epics/dialogEpics';
import layoutEpics from './epics/layoutEpics';
import mapLayerConfigurationEpics from './epics/mapLayerConfigurationEpics';
import mapSceneEpics from './epics/mapSceneEpics';
import notificationModuleEpics from './epics/notificationModuleEpics';
import releaseNotesEpics from './epics/releaseNotesEpics';
import reloadingEpics from './epics/reloadingEpics';
import trafficCenterEpics from './epics/trafficCenterEpics';
import userSettingsEpics from './epics/userSettings';
import viewerWebsocketServiceEpics from './epics/viewerWebsocketService';

const setKeycloakAuthenticationInDevelopmentOnApplicationBooted: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApplicationBooted)),
        filter(() => ENVIRONMENT === 'DEV'),
        map(() => setKeycloakAuthenticationInDevelopmentMode())
    );

const startKeycloakAuthenticationOnApplicationBooted: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApplicationBooted)),
        filter(() => ENVIRONMENT === 'ACC' || ENVIRONMENT === 'PROD'),
        mergeMap(() => keycloakService.setupKeycloak('/keycloak.json?cache-bust-for=iam-migration'))
    );

const rootEpics: Epic = combineEpics(
    setKeycloakAuthenticationInDevelopmentOnApplicationBooted,
    startKeycloakAuthenticationOnApplicationBooted,

    applicationEpics,
    configurationEpics,
    dialogEpics,
    layoutEpics,
    keycloakAuthenticationEpics,
    mapSceneEpics,
    mapLayerConfigurationEpics,
    notificationModuleEpics,
    reloadingEpics,
    releaseNotesEpics,
    trafficCenterEpics,
    userSettingsEpics,
    viewerWebsocketServiceEpics
);

export default rootEpics;
