/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HazardWarningHistoryDto } from '../models/HazardWarningHistoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HazardWarningControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns string OK
     * @throws ApiError
     */
    public getAllTypes(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/proxy/romoHazardWarnings/hazard-warnings/types',
        });
    }

    /**
     * @param id
     * @returns HazardWarningHistoryDto OK
     * @throws ApiError
     */
    public getHistoryForHazardWarningId(
        id: string,
    ): CancelablePromise<Array<HazardWarningHistoryDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/proxy/romoHazardWarnings/hazard-warnings/history/{id}',
            path: {
                'id': id,
            },
        });
    }

}
