import React from 'react';
import {HazardWarningHistoryDto} from '../../../../../../generated/ViewerApiV2Client';
import {LineChart, MultiChartDataSet} from 'react-chartkick';

const HazardWarningHistory: React.FunctionComponent<{
    history: HazardWarningHistoryDto[];
    minIntensity: number;
    maxIntensity: number;
}> = ({history, minIntensity, maxIntensity}) => {
    const data = history.reduce<{[key: string]: number}>(
        (acc, {dateTime, intensity}) => {
            if (dateTime == null || intensity == null) {
                return acc;
            }
            acc[dateTime] = intensity;
            return acc;
        },
        {}
    );

    const historyChartData: MultiChartDataSet[] = [
        {
            data: data,
            name: 'Zwaarte'
        }
    ];

    return (
        <LineChart
            data={historyChartData}
            min={minIntensity}
            max={maxIntensity + 1} // increase max to add whitespace to y axis to prevent truncating points on chart's line
        />
    );
};

export default HazardWarningHistory;
