import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    header: {
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '30px',
        paddingBottom: 5,
        paddingTop: 5
    },
    list: {
        listStyleType: 'disc',
        marginBottom: '1.5em',
        marginLeft: '1.5em'
    }
});

export default styles;
