import {Reducer} from 'redux';
import {
    MapSceneFilterAndSearchReducerActionTypes,
    selectedNewTrafficCenter,
    setNewDfineFilterStatusOnlyRvmNetwork,
    setNewFdVerifiedTrafficJamsFilterStatus,
    setNewPrefixFilterForLayer,
    setNewRomoJerkFilter,
    setNewRomoJerkDeltaFilter,
    setNewRomoSizeFilter,
    setNewRomoSizeDeltaFilter,
    setNewRomoTimeFilter,
    setNewRomoRoadOperatorFilter,
    setNewWazeAlertFilterStatusSet,
    setNewWazeAlertNdwKnownFilterStatus,
    setSituationTypeFilter,
    storeNewSearchResult,
    storeNewSearchValue,
    loadedFilterDataRomoHW,
    setRomoHWSelectedTypes,
    setNewRomoHWDaysInHistoryFilter
} from '../actions/reducers/filterAndSearch';
import UrlService from '../../../services/UrlService';
import {SearchResults} from '../../../interfaces/SearchFeature';
import {getType} from 'typesafe-actions';
import {otisSituationTypeFilter} from '../../../constants';

export interface PrefixLayerFilter {
    mapLayerIDs: string[];
    prefixes: string[];
}

export interface PrefixLayerFilters {
    flowSpeed: PrefixLayerFilter;
    travelTimeFcd: PrefixLayerFilter;
}

export interface MapFilters {
    currentTrafficCenter: string | null;
    filterTimeStamp: number;
    prefixFilter: PrefixLayerFilters;
    wazeAlertNdwKnown: boolean;
    wazeAlertStatusSet: boolean;
    dFineOnlyRvmNetwork: boolean;
    fdVerifiedTrafficJams: boolean;
    situationTypeFilterOptions: SituationTypeFilter[];
    situationTypeFilters: SituationTypeFilter[];
    romo: RomoFilters
    romoHW: RomoHWFilters;
    romoSD: RomoSDFilters;
}

export interface RomoFilters {
    roadOperatorId: string
}

export interface RomoHWFilters {
    selectedTypes: string[];
    types: string[],
    maxDaysInHistory: number;
}

export interface RomoSDFilters {
    sizeFilter: number;
    sizeDeltaFilter: number;
    jerkFilter: number;
    jerkDeltaFilter: number;
    timeFilter: number;
}

export interface SituationTypeFilter {
    label: string;
    value: string
}

export interface MapSceneFilterAndSearchReducerState {
    currentSearchValue: string;
    mapFilters: MapFilters;
    searchResults: SearchResults | null;
}

const currentTrafficCenter = UrlService.getInstance().getStringValueFromUrl('trafficCenter', '');
const initialState: MapSceneFilterAndSearchReducerState = {
    currentSearchValue: '',
    mapFilters: {
        currentTrafficCenter: currentTrafficCenter.length > 0 ? currentTrafficCenter : null,
        dFineOnlyRvmNetwork: UrlService.getInstance().getBooleanValueFromUrl('mapFilterDfineRvmNetworkOnly', true),
        filterTimeStamp: Date.now(),
        prefixFilter: UrlService.getInstance().getObjectValueFromUrl('prefixFilters', {
            flowSpeed: {
                mapLayerIDs: ['flowSpeedArrow', 'flowSpeedCircle'],
                prefixes: []
            },
            travelTimeFcd: {
                mapLayerIDs: ['travelTimesFcdLabel', 'travelTimesFcdLine'],
                prefixes: []
            }
        }),
        wazeAlertNdwKnown: UrlService.getInstance().getBooleanValueFromUrl('mapFilterWazeAlertNdwKnown', false),
        wazeAlertStatusSet: UrlService.getInstance().getBooleanValueFromUrl('mapFilterWazeAlertStatusSet', false),
        fdVerifiedTrafficJams: UrlService.getInstance().getBooleanValueFromUrl('mapFilterFdVerifiedTrafficJams', true),
        situationTypeFilters: [],
        situationTypeFilterOptions: otisSituationTypeFilter,
        romo: {
            roadOperatorId: UrlService.getInstance().getStringValueFromUrl('mapFilterRomoByRoadOperator', '')
        },
        romoHW: {
            selectedTypes: UrlService.getInstance().getObjectValueFromUrl('mapFilterRomoHWSelectedTypes', []),
            types: [],
            maxDaysInHistory: UrlService.getInstance().getNumericValueFromUrl('mapFilterRomoHWMaxDaysInHistory', 0)
        },
        romoSD: {
            sizeFilter: UrlService.getInstance().getNumericValueFromUrl('mapFilterRomoBySize', 18),
            sizeDeltaFilter: UrlService.getInstance().getNumericValueFromUrl('mapFilterRomoBySizeDelta', 0),
            jerkFilter: UrlService.getInstance().getNumericValueFromUrl('mapFilterRomoByJerk', 18),
            jerkDeltaFilter: UrlService.getInstance().getNumericValueFromUrl('mapFilterRomoByJerkDelta', 0),
            timeFilter: UrlService.getInstance().getNumericValueFromUrl('mapFilterRomoByTime', 8)
        }
    },
    searchResults: null
};

const filterAndSearchReducer: Reducer<MapSceneFilterAndSearchReducerState, MapSceneFilterAndSearchReducerActionTypes> =
    (state: MapSceneFilterAndSearchReducerState = initialState, action: MapSceneFilterAndSearchReducerActionTypes): MapSceneFilterAndSearchReducerState => {
        switch (action.type) {
            case getType(selectedNewTrafficCenter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        currentTrafficCenter: action.payload.newTrafficCenter ? action.payload.newTrafficCenter.key : null,
                        filterTimeStamp: Date.now()
                    }
                };
            case getType(storeNewSearchValue):
                return {
                    ...state,
                    currentSearchValue: action.payload.searchValue
                };
            case getType(setNewDfineFilterStatusOnlyRvmNetwork):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        dFineOnlyRvmNetwork: action.payload.newStatus,
                        filterTimeStamp: Date.now()
                    }
                };
            case getType(setNewWazeAlertNdwKnownFilterStatus):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        wazeAlertNdwKnown: action.payload.newStatus
                    }
                };
            case getType(setNewWazeAlertFilterStatusSet):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        wazeAlertStatusSet: action.payload.newStatus
                    }
                };
            case getType(storeNewSearchResult):
                return {
                    ...state,
                    searchResults: action.payload.searchResults
                };
            case getType(setNewPrefixFilterForLayer):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        prefixFilter: {
                            ...state.mapFilters.prefixFilter,
                            [action.payload.layerId]: {
                                ...state.mapFilters.prefixFilter[action.payload.layerId],
                                prefixes: action.payload.prefixes
                            }
                        }
                    }
                };
            case getType(setNewFdVerifiedTrafficJamsFilterStatus):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        fdVerifiedTrafficJams: action.payload.newStatus
                    }
                };
            case getType(setSituationTypeFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        situationTypeFilters: action.payload.situationTypeFilters
                    }
                };
            case getType(setNewRomoSizeFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        romoSD: {
                            ...state.mapFilters.romoSD,
                            sizeFilter: action.payload.size
                        }
                    }
                };
            case getType(setNewRomoSizeDeltaFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        romoSD: {
                            ...state.mapFilters.romoSD,
                            sizeDeltaFilter: action.payload.sizeDelta
                        }
                    }
                };
            case getType(setNewRomoJerkFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        romoSD: {
                            ...state.mapFilters.romoSD,
                            jerkFilter: action.payload.jerk
                        }
                    }
                };
            case getType(setNewRomoJerkDeltaFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        romoSD: {
                            ...state.mapFilters.romoSD,
                            jerkDeltaFilter: action.payload.jerkDelta
                        }
                    }
                };
            case getType(setNewRomoTimeFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        romoSD: {
                            ...state.mapFilters.romoSD,
                            timeFilter: action.payload.weeks
                        }
                    }
                };
            case getType(setNewRomoRoadOperatorFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        romo: {
                            ...state.mapFilters.romoHW,
                            roadOperatorId: action.payload.roadOperatorId
                        }
                    }
                };
            case getType(loadedFilterDataRomoHW):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        romoHW: {
                            ...state.mapFilters.romoHW,
                            types: action.payload.types
                        }
                    }
                };
            case getType(setRomoHWSelectedTypes):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        romoHW: {
                            ...state.mapFilters.romoHW,
                            selectedTypes: action.payload.selectedTypes
                        }
                    }
                };
            case getType(setNewRomoHWDaysInHistoryFilter):
                return {
                    ...state,
                    mapFilters: {
                        ...state.mapFilters,
                        filterTimeStamp: Date.now(),
                        romoHW: {
                            ...state.mapFilters.romoHW,
                            maxDaysInHistory: action.payload.maxDaysInHistory
                        }
                    }
                };
            default:
                return {
                    ...state
                };
        }
    };

export default filterAndSearchReducer;
