import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {updateSidebarStatus} from '../../../scenes/MapScene/actions';
import {filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {loadHistoricalSummedData} from '../../../services/ViewerApiService';
import {RootState} from '../../../reducer';
import {interval} from 'rxjs';
import {
    appendNewTrajectoriesToSummedTrajectoriesPaneList,
    clearSummedHistoricalTrajectoriesData,
    removeTrajectoryFromSelection,
    storeNewTrajectoriesSummedGraphData
} from '../../../scenes/MapScene/actions/reducers/summedTrajactoriesGraph';
import {keycloakService} from '@ndw/react-keycloak-authentication';
import {viewerApiServiceReceivedHistoricalSummedData} from '../../../services/ViewerApiService/actions/historicalSummedDataData';

const clearSummedHistoricalDataAfterLastItemsRemoved: Epic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(removeTrajectoryFromSelection)),
        filter(() => state$.value.mapScene.trajectorySummedSelection.length === 0),
        map(() => clearSummedHistoricalTrajectoriesData())
    );

const loadHistoricalDataOnAppendNewTrajectoriesToSummedTrajectoriesPaneList: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(appendNewTrajectoriesToSummedTrajectoriesPaneList)),
        filter(() => state$.value.mapScene.summedTrajectoriesGraph.trajectorySummedSelection.length > 0),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => loadHistoricalSummedData(
                    state$.value.mapScene.summedTrajectoriesGraph.trajectorySummedSelection as string[]
                ))
            ))
    );

const loadInitialDataAfterTrajectorySummedPaneBecomesActive: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(updateSidebarStatus)),
        filter((action) => action.payload.newVisibleTab === 'TRAJECTORY_SUMMED'),
        filter(() => state$.value.mapScene.summedTrajectoriesGraph.trajectorySummedSelection.length > 0),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => loadHistoricalSummedData(
                    state$.value.mapScene.summedTrajectoriesGraph.trajectorySummedSelection as string[]
                ))
            ))
    );

const reloadDataOnRemoveTrajectoryFromSelection: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(removeTrajectoryFromSelection)),
        filter(() => state$.value.mapScene.summedTrajectoriesGraph.trajectorySummedSelection.length > 0),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => loadHistoricalSummedData(
                    state$.value.mapScene.summedTrajectoriesGraph.trajectorySummedSelection as string[]
                ))
            ))
    );

const setupIntervalReloadingOfHistoricalSummedTrajectories: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(updateSidebarStatus)),
        filter((action) => action.payload.newVisibleTab === 'TRAJECTORY_SUMMED'),
        switchMap(() =>
            interval(60000)
                .pipe(
                    takeUntil(action$.pipe(
                        filter(isActionOf(updateSidebarStatus)),
                        filter((action) => action.payload.newVisibleTab !== 'TRAJECTORY_SUMMED')
                    )),
                    filter(() => state$.value.mapScene.summedTrajectoriesGraph.trajectorySummedSelection.length > 0),
                    switchMap(() => keycloakService.token()
                        .pipe(
                            mergeMap(() => loadHistoricalSummedData(
                                state$.value.mapScene.summedTrajectoriesGraph.trajectorySummedSelection as string[]
                            ))
                        ))
                )
        )
    );

const storeNewTrajectoriesSummedGraphDataOnReceivedHistoricalSummedData: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApiServiceReceivedHistoricalSummedData)),
        map((action) => storeNewTrajectoriesSummedGraphData(action.payload.data))
    );

const summedTrajectoriesPane: Epic = combineEpics(
    clearSummedHistoricalDataAfterLastItemsRemoved,
    loadHistoricalDataOnAppendNewTrajectoriesToSummedTrajectoriesPaneList,
    loadInitialDataAfterTrajectorySummedPaneBecomesActive,
    reloadDataOnRemoveTrajectoryFromSelection,
    setupIntervalReloadingOfHistoricalSummedTrajectories,
    storeNewTrajectoriesSummedGraphDataOnReceivedHistoricalSummedData
);

export default summedTrajectoriesPane;
