import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import MessageAgeInMinutes from '../../../../../../components/MessageAgeInMinutes';
import BearingCompass from '../../../../../../components/BearingCompass';

class DialogMrm extends React.Component<Props> {

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {properties, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Weg</th>
                        <td>{properties.road} {properties.carriageway} {properties.lane} {properties.km}</td>
                    </tr>
                    <tr>
                        <th>Richting</th>
                        <td>
                            <BearingCompass
                                bearing={properties.bearing}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Aantal voertuigen</th>
                        <td>{properties.flow}</td>
                    </tr>
                    <tr>
                        <th>Snelheid</th>
                        <td>{properties.speed}</td>
                    </tr>
                    <tr>
                        <th>Tijdstip meeting</th>
                        <td>
                            <DateTimeFormatted
                                dateTime={properties.ts_event}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Tijd sinds laatste meeting</th>
                        <td>
                            <MessageAgeInMinutes
                                time={properties.ts_event}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>;
    }

}

export default DialogMrm;
