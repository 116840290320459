import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, flatMap, map, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {keycloakInstanceCreated, refreshAccessToken, setKeycloakAuthenticationInDevelopmentMode} from '@ndw/react-keycloak-authentication';
import queryString from 'query-string';
import {push} from 'connected-react-router';
import {viewerApplicationNavigationRequested} from '../../actions';
import {MAP} from '../../routes';
import {RootState} from '../../reducer';
import {RELOADING_INTERVALS} from '../../applicationContext';
import {getNativeWindow} from '../../services/WindowService';
import {startReloadingInterval} from '../../modules/ReloadingReducer/actions';
import {of} from 'rxjs';
import {viewerApiServiceReceivingSourceDataFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/sourceDataData';
import {viewerApiServiceReceivingHistoricalSummedDataFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/historicalSummedDataData';
import {viewerApiServiceReceivingHistoricalDataFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/historicalDataData';
import {
    viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError,
    viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError
} from '../../services/ViewerApiService/actions/wazeStatusData';
import {viewerApiServiceLoadingTrafficCentersDataUnauthorizedError} from '../../services/ViewerApiService/actions/trafficCentersData';
import {viewerApiServiceReceivingWazeStatusCloseFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/wazeStatusCloseData';
import {viewerApiServiceSavingNotificationFailedWithUnauthorizedException} from '../../services/ViewerApiService/actions/notificationPane';
import {loadAccessData, loadPrefixes} from '../../services/ViewerApiService';
import {viewerApiServiceReceivingSearchFeaturesDataFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/loadSearchFeaturesData';
import {viewerApiServiceReceivingBridgeHistoryFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/loadBridgeHistory';
import {viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnauthorizedException} from '../../services/ViewerApiService/actions/segmentHeatMap';

const loadAccessDataOnKeycloakInstanceCreated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([keycloakInstanceCreated, setKeycloakAuthenticationInDevelopmentMode])),
        mergeMap(() => loadAccessData())
    );

const loadPrefixDataOnKeycloakInstanceCreated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            keycloakInstanceCreated,
            setKeycloakAuthenticationInDevelopmentMode
        ])),
        mergeMap(() => loadPrefixes())
    );

const navigateToRouteOnNavigationRequested: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApplicationNavigationRequested)),
        map((action) => {
            const window = getNativeWindow();
            return push(`${action.payload.route}${window.location.hash}`);
        })
    );

const goToRedirectRouteOnKeycloakInstanceCreated: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(keycloakInstanceCreated)),
        map(() => {
            const information = queryString.parse(state$.value.router.location.search);
            let targetRoute = MAP;
            if (information && information.redirect) {
                targetRoute = information.redirect as string;
            }
            return viewerApplicationNavigationRequested(targetRoute);
        })
    );

const startReloadingTwentySecondsOnKeycloakInstanceCreated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([keycloakInstanceCreated, setKeycloakAuthenticationInDevelopmentMode])),
        flatMap(() => of(...RELOADING_INTERVALS).pipe(map((value) => startReloadingInterval(value))))
    );

const refreshAccessTokenOnUnauthorizedResponseFromViewerApiService: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            viewerApiServiceReceivingHistoricalSummedDataFailedWithUnauthorizedError,
            viewerApiServiceReceivingSourceDataFailedWithUnauthorizedError,
            viewerApiServiceReceivingHistoricalDataFailedWithUnauthorizedError,
            viewerApiServiceReceivingBridgeHistoryFailedWithUnauthorizedError,
            viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError,
            viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError,
            viewerApiServiceLoadingTrafficCentersDataUnauthorizedError,
            viewerApiServiceReceivingWazeStatusCloseFailedWithUnauthorizedError,
            viewerApiServiceSavingNotificationFailedWithUnauthorizedException,
            viewerApiServiceReceivingSearchFeaturesDataFailedWithUnauthorizedError,
            viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnauthorizedException
        ])),
        map(() => refreshAccessToken())
    );

const applicationEpics: Epic = combineEpics(
    goToRedirectRouteOnKeycloakInstanceCreated,
    loadAccessDataOnKeycloakInstanceCreated,
    navigateToRouteOnNavigationRequested,
    startReloadingTwentySecondsOnKeycloakInstanceCreated,
    refreshAccessTokenOnUnauthorizedResponseFromViewerApiService,
    loadPrefixDataOnKeycloakInstanceCreated
);

export default applicationEpics;
