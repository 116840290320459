import React, {CSSProperties, useMemo} from 'react';
import {Props} from './Props';
import TrafficMessageRow from './components/TrafficMessageRow';
import StyledButton from '../../../../../../components/StyledButton';
import _ from 'lodash';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {SituationFeature} from '../../../../../../interfaces/SituationFeature';
import {DataSourceGeoJson} from '../../../../../../interfaces/DataSource';
import {trafficInformationResources} from '../../../../../../constants';
import {TrafficInformationSource} from './TrafficInformation';
import {SortingDirection, SortingValue} from '../../../../../../interfaces/TrafficInformation';

const TrafficMessagePane: React.FunctionComponent<Readonly<Props>> = (
    {
        mapMovementAvailable,
        trafficMessages,
        onClickedOnSituationFeature,
        isSortByValue,
        isSortInDirection,
        onUpdateSortTrafficMessagesByValue,
        onUpdateSortTrafficMessagesInDirection,
        currentTrafficCenter
    }) => {

    const sortTrafficMessages = (copiedTrafficMessages: SituationFeature[]) => {
        if (copiedTrafficMessages != null) {
            copiedTrafficMessages = isSortByValue === SortingValue.byRoadName ? sortByRoadName(copiedTrafficMessages) : sortByCreationDate(copiedTrafficMessages);
        }
        return copiedTrafficMessages;
    };

    const sortByCreationDate = (situationFeatures: SituationFeature[]) => {
        if (isSortInDirection === SortingDirection.ascending) {
            return situationFeatures.sort((a, b) => new Date(a.properties.overallStartTime).valueOf() - new Date(b.properties.overallStartTime).valueOf());
        } else {
            return situationFeatures.sort((a, b) => new Date(b.properties.overallStartTime).valueOf() - new Date(a.properties.overallStartTime).valueOf());
        }
    };

    const compareLocationDescription = (situationFeature1: SituationFeature, situationFeature2: SituationFeature
        , sortDirection: SortingDirection): number => {
        let a = situationFeature1;
        let b = situationFeature2;
        if (sortDirection === SortingDirection.descending) {
            a = situationFeature2;
            b = situationFeature1;
        }
        if (!a.properties.locationDescription && !b.properties.locationDescription) {
            return 0;
        } else if (!a.properties.locationDescription) {
            return 1;
        } else if (!b.properties.locationDescription) {
            return -1;
        }
        return a.properties.locationDescription
            .localeCompare(b.properties.locationDescription, undefined, {numeric: true});
    };

    const sortByRoadName = (situationFeatures: SituationFeature[]) => {
        return situationFeatures.sort((a, b) => compareLocationDescription(a, b, isSortInDirection));
    };

    const features = trafficInformationResources.flatMap((trafficInformationSource: TrafficInformationSource) => {
        return (trafficMessages[trafficInformationSource.dataSource] as DataSourceGeoJson)?.layerMapData?.features
            .map(feature => feature as SituationFeature)
            .filter(situationFeature => !currentTrafficCenter || situationFeature.properties.trafficCenter === currentTrafficCenter?.key)
            .filter(feature => feature.properties.dataType === trafficInformationSource.dataType)
            .filter(feature => !!feature.properties.locationDescription)
            || [];
    });

    const sortedTrafficMessages =
        useMemo(() => sortTrafficMessages(_.cloneDeep(features)),
            [trafficMessages, isSortInDirection, isSortByValue, currentTrafficCenter]);

    const noResultsStyle: CSSProperties = {textAlign: 'center', paddingTop: '10px'};

    return <React.Fragment>
        <p>Sorteer op:</p>

        <StyledButton
            onPress={
                () => {
                    isSortByValue = SortingValue.byRoadName;
                    onUpdateSortTrafficMessagesByValue(isSortByValue);
                }
            }
            title={'Alfabetisch'}
            selected={isSortByValue === SortingValue.byRoadName}
        />
        <StyledButton
            onPress={
                () => {
                    isSortByValue = SortingValue.byCreationDate;
                    onUpdateSortTrafficMessagesByValue(isSortByValue);
                }
            }
            title={'Op datum'}
            selected={isSortByValue === SortingValue.byCreationDate}
        />

        <div className={css(styles.locateButtonOnTheRight)}>
            <StyledButton
                onPress={
                    () => {
                        isSortInDirection = SortingDirection.descending;
                        onUpdateSortTrafficMessagesInDirection(isSortInDirection);
                    }
                }
                title={<span className="fas fa-sort-amount-down"></span>}
                selected={isSortInDirection === SortingDirection.descending}
                aria-label="Sorteer aflopend"
            />
            <StyledButton
                onPress={
                    () => {
                        isSortInDirection = SortingDirection.ascending;
                        onUpdateSortTrafficMessagesInDirection(isSortInDirection);
                    }
                }
                title={<span className="fas fa-sort-amount-up"></span>}
                selected={isSortInDirection === SortingDirection.ascending}
                aria-label="Sorteer oplopend"
            />
        </div>
        {sortedTrafficMessages.length === 0 && <div style={noResultsStyle}>Geen resultaten gevonden.</div>}
        {sortedTrafficMessages.map((situationFeature: SituationFeature, index: number) => <TrafficMessageRow
            situationFeature={situationFeature}
            key={index}
            highlight={index % 2 === 0}
            clickAvailable={mapMovementAvailable}
            onClickedOnSituationFeature={() => onClickedOnSituationFeature(situationFeature)}
        />)}
    </React.Fragment>;
};

export default TrafficMessagePane;
