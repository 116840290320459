import {ActionType, createAction} from 'typesafe-actions';
import {LayerDTO} from '../../../../generated/ViewerApiClient';
import {DataSource} from '../../../../interfaces/DataSource';
import {StreamableSnapshot} from '../../../../models';

export const viewerApiServiceReceivedSourceData = createAction(
    'VIEWER_API_SERVICE_RECEIVED_SOURCE_DATA',
    (action) => (data: LayerDTO | StreamableSnapshot, mapSource: DataSource, dataType: string) => action({data, mapSource, dataType})
);

export const viewerApiServiceReceivedSourceDataWithNoUpdate = createAction(
    'VIEWER_API_SERVICE_RECEIVED_SOURCE_DATA_WITH_NO_UPDATE',
    (action) => () => action({})
);

export const viewerApiServiceReceivingSourceDataFailedWithBadRequest = createAction(
    'VIEWER_API_SERVICE_RECEIVING_SOURCE_DATA_FAILED_WITH_BAD_REQUEST',
    (action) => () => action({})
);

export const viewerApiServiceReceivingSourceDataFailedWithUnauthorizedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_SOURCE_DATA_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const viewerApiServiceReceivingSourceDataFailedWithNotFoundError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_SOURCE_DATA_FAILED_WITH_NOT_FOUND_ERROR',
    (action) => (sourceID: string, sourceName: string, layerType: string) => action({sourceID, sourceName, layerType})
);

export const viewerApiServiceReceivingSourceDataUnexpectedError = createAction(
    'VIEWER_API_SERVICE_RECEIVING_SOURCE_DATA_UNEXPECTED_ERROR',
    (action) => (statusCode: number, sourceID: string, sourceName: string, layerType: string) =>
        action({statusCode, sourceID, sourceName, layerType})
);

export type SourceDataDataActionTypes = ActionType<typeof viewerApiServiceReceivedSourceData> |
    ActionType<typeof viewerApiServiceReceivedSourceDataWithNoUpdate> |
    ActionType<typeof viewerApiServiceReceivingSourceDataFailedWithBadRequest> |
    ActionType<typeof viewerApiServiceReceivingSourceDataFailedWithUnauthorizedError> |
    ActionType<typeof viewerApiServiceReceivingSourceDataFailedWithNotFoundError> |
    ActionType<typeof viewerApiServiceReceivingSourceDataUnexpectedError>;
