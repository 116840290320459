import {RoadOperatorType} from '../RoadOperator';

export enum HazardWarningType {
    BROKEN_DOWN_VEHICLE = 'BROKEN_DOWN_VEHICLE',
    VEHICLE_ACCIDENT = 'VEHICLE_ACCIDENT',
    GENERAL_WARNING = 'GENERAL_WARNING',
    HAZARD_LIGHT = 'HAZARD_LIGHT',
    SLIPPERY_ROAD = 'SLIPPERY_ROAD',
    FOG = 'FOG',
    HEAVY_RAIN = 'HEAVY_RAIN',
    EMERGENCY_BRAKE = 'EMERGENCY_BRAKE',
    CROSS_WIND = 'CROSS_WIND',
    POTHOLE = 'POTHOLE',
    BUMP = 'BUMP',
}


export interface GeoJsonPropertiesRomoHazardWarnings {
    id: string;
    type: HazardWarningType;
    bearing?: number;
    intensity?: number;
    dateTime: number;
    roadOperatorCode?: string;
    roadOperatorType?: RoadOperatorType;
    roadOperatorName?: string;
    roadOperatorId?: string;
}

export function assertHasGeoJsonPropertiesRomoHazardWarnings(
    props: unknown
): asserts props is GeoJsonPropertiesRomoHazardWarnings {
    if (
        props === null ||
        props === undefined ||
        typeof props !== 'object' ||
        !('type' in props) ||
        !('id' in props) ||
        !('dateTime' in props)
    ) {
        throw new Error('Properties are not of type hazard warnings');
    }
}
