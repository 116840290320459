import React, {useMemo} from 'react';
import {Props} from './Props';
import {OtmConstraintAnd} from '../OtmConstraintAnd';
import {
    GeoJsonPropertiesRoadWorkPlannedOtmConstraintAnd,
    GeoJsonPropertiesRoadWorkPlannedOtmConstraintGeneric,
    GeoJsonPropertiesRoadWorkPlannedOtmConstraintOr,
    GeoJsonPropertiesRoadWorkPlannedOtmConstraintTimeWindow,
    GeoJsonPropertiesRoadWorkPlannedOtmConstraintValueBound,
    GeoJsonPropertiesRoadWorkPlannedOtmConstraintVehicleType
} from '../../../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesRoadWorkPlannedOtm';
import {OtmConstraintOr} from '../OtmConstraintOr';
import {OtmConstraintVehicleType} from '../OtmConstraintVehicleType';
import {OtmConstraintTimeWindow} from '../OtmConstraintTimeWindow';
import {OtmConstraintValueBoundConstraint} from '../OtmConstraintValueBoundConstraint';
import {OtmConstraintGeneric} from '../OtmConstraintGeneric';

export const OtmConstraint: React.FunctionComponent<Props> = ({constraint, level}) => {

    return <>

        {useMemo(() => {

            if (constraint.type === 'andConstraint') {
                return <>
                    <OtmConstraintAnd
                        constraint={constraint as GeoJsonPropertiesRoadWorkPlannedOtmConstraintAnd}
                        level={level}
                    />
                </>;
            } else if (constraint.type === 'orConstraint') {
                return <>
                    <OtmConstraintOr
                        constraint={constraint as GeoJsonPropertiesRoadWorkPlannedOtmConstraintOr}
                        level={level}
                    />
                </>;
            } else if (constraint.type === 'valueBoundConstraint') {
                return <>
                    <OtmConstraintValueBoundConstraint
                        constraint={constraint as GeoJsonPropertiesRoadWorkPlannedOtmConstraintValueBound}
                        level={level}
                    />
                </>;
            } else if (constraint.type === 'vehicleTypeConstraint') {
                return <>
                    <OtmConstraintVehicleType
                        constraint={constraint as GeoJsonPropertiesRoadWorkPlannedOtmConstraintVehicleType}
                        level={level}
                    />
                </>;
            } else if (constraint.type === 'timeWindowConstraint') {
                return <>
                    <OtmConstraintTimeWindow
                        constraint={constraint as GeoJsonPropertiesRoadWorkPlannedOtmConstraintTimeWindow}
                        level={level}
                    />
                </>;
            } else if (constraint.type === 'genericConstraint') {
                return <>
                    <OtmConstraintGeneric
                        constraint={constraint as GeoJsonPropertiesRoadWorkPlannedOtmConstraintGeneric}
                        level={level}
                    />
                </>;
            } else {
                return <>
                    <p>Unsupported type: {constraint.type}</p>
                </>;
            }
        }, [constraint])}
    </>;
};
