import {HazardWarningType} from '../../interfaces/GeoJsonSources/GeoJsonPropertiesRomoHazardWarnings';

export const TYPE_NAMES: Readonly<{[k in HazardWarningType]: string}> = {
    [HazardWarningType.BROKEN_DOWN_VEHICLE]: 'Defect voertuig',
    [HazardWarningType.VEHICLE_ACCIDENT]: 'Ongeval',
    [HazardWarningType.GENERAL_WARNING]: 'Algemene waarschuwing',
    [HazardWarningType.HAZARD_LIGHT]: 'Gevarenlicht',
    [HazardWarningType.SLIPPERY_ROAD]: 'Slipgevaar',
    [HazardWarningType.FOG]: 'Mist',
    [HazardWarningType.HEAVY_RAIN]: 'Zware regen',
    [HazardWarningType.EMERGENCY_BRAKE]: 'Noodstop',
    [HazardWarningType.CROSS_WIND]: 'Zijwind',
    [HazardWarningType.POTHOLE]: 'Kuil',
    [HazardWarningType.BUMP]: 'Slecht wegdek'
};