import React, {ReactElement} from 'react';
import {css} from 'aphrodite/no-important';
import {colorBlindnessStyles, darkStyles, styles} from './styles';
import {Props} from './Props';
import SidebarButton from './components/SidebarButton';
import SidebarPane from './components/SidebarPane';
import UserSettingsPane from './components/UserSettingsPane';
import TrafficMessagePane from './components/TrafficMessagesPane';
import MapLayerPane from './components/MapLayerPane';
import TrajectorySummedPane from './components/TrajectorySummedPane';
import OverviewAllPane from './components/OverviewAllPane';
import FilterPane from './components/FilterPane';
import ReleaseNotesPane from './components/ReleaseNotesPane';
import LegendPane from './components/LegendPane';
import {overviewAllTables} from '../../../../constants';
import NotificationPane from './components/NotificationPane';
import ThemeContext from '../../../../contexts/ThemeContext';
import {SidebarContentPanes} from '../../reducers/generalReducer';

class Sidebar extends React.Component<Props> {

    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;

    onUpdateSidebarStatus = (newTab: SidebarContentPanes): void => {
        const props: Readonly<Props> = this.props;
        const {sidebarVisibleTab} = props;

        if (sidebarVisibleTab === newTab) {
            return props.onUpdateSidebarStatus(null);
        }

        props.onUpdateSidebarStatus(newTab);
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {
            currentSearchValue,
            currentTrafficCenter,
            currentThemeID,
            currentVisibleTable,
            legendData,
            mapFilter,
            mapLayers,
            mapSettings,
            mapSources,
            prefixes,
            releaseNote,
            searchResults,
            sidebarVisibleTab,
            trafficCenters,
            trafficMessages,
            trajectorySummedData,
            trajectorySummedSelection,
            trajectorySummedSelectionEnabled,
            showRecordIds,
            showTrafficJamIcons,
            mapMovementAvailable
        } = props;

        return <div className={css(
            styles.sidebarContainer,
            sidebarVisibleTab === null && styles.sidebarContainerCollapsed,
            this.context.currentColorSchemeIsDark && darkStyles.sidebarContainer
        )}>
            <div
                className={css(
                    styles.sidebarTabsWrapper,
                    this.context.currentColorSchemeIsDark && darkStyles.sidebarTabsWrapper,
                    this.context.currentColorSchemeIsSuitableForColorBlindness && colorBlindnessStyles.sidebarTabsWrapper
                )}>
                <SidebarButton
                    pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.LAYERS)}
                    active={sidebarVisibleTab === SidebarContentPanes.LAYERS}
                    uniqueId="layers-button"
                    iconClassName="fas fa-bars"
                    label="kaartlagen"
                    aria-controls={`pane-${SidebarContentPanes.LAYERS}`}
                />
                <SidebarButton
                    pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.FILTERS)}
                    active={sidebarVisibleTab === SidebarContentPanes.FILTERS}
                    iconClassName="fas fa-filter"
                    label="filter"
                    aria-controls={`pane-${SidebarContentPanes.FILTERS}`}
                />
                <SidebarButton
                    pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.TRAFFIC_INFORMATION)}
                    active={sidebarVisibleTab === SidebarContentPanes.TRAFFIC_INFORMATION}
                    iconClassName="fas fa-exclamation-triangle"
                    label="verkeersinformatie"
                    aria-controls={`pane-${SidebarContentPanes.TRAFFIC_INFORMATION}`}
                />
                <SidebarButton
                    pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.TRAJECTORY_SUMMED)}
                    active={sidebarVisibleTab === SidebarContentPanes.TRAJECTORY_SUMMED}
                    iconClassName="fas fa-chart-bar"
                    label="trajecten"
                    aria-controls={`pane-${SidebarContentPanes.TRAJECTORY_SUMMED}`}
                />
                <SidebarButton
                    active={sidebarVisibleTab === SidebarContentPanes.OVERVIEW_ALL}
                    pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.OVERVIEW_ALL)}
                    iconClassName="fas fa-table"
                    label="tabelconfiguratie"
                    aria-controls={`pane-${SidebarContentPanes.OVERVIEW_ALL}`}
                />

                <div className={css(styles.sidebarTabsBottom)}>
                    {props.checkPermissionForFeature('viewer_update_notification') &&
                    <SidebarButton
                        pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.NOTIFICATION)}
                        active={sidebarVisibleTab === SidebarContentPanes.NOTIFICATION}
                        iconClassName="fas fa-bell"
                        label="notificaties"
                        aria-controls={`pane-${SidebarContentPanes.NOTIFICATION}`}
                    />}
                    <SidebarButton
                        pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.LEGEND)}
                        active={sidebarVisibleTab === SidebarContentPanes.LEGEND}
                        iconClassName="fas fa-info-circle"
                        label="legenda"
                        aria-controls={`pane-${SidebarContentPanes.LEGEND}`}
                    />
                    <SidebarButton
                        pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.RELEASE_NOTES)}
                        active={sidebarVisibleTab === SidebarContentPanes.RELEASE_NOTES}
                        iconClassName="fas fa-code-branch"
                        label="versiegeschiedenis"
                        aria-controls={`pane-${SidebarContentPanes.RELEASE_NOTES}`}
                    />
                    <SidebarButton
                        pressedItem={() => this.onUpdateSidebarStatus(SidebarContentPanes.CONFIGURATION)}
                        active={sidebarVisibleTab === SidebarContentPanes.CONFIGURATION}
                        iconClassName="fas fa-cogs"
                        label="instellingen"
                        aria-controls={`pane-${SidebarContentPanes.CONFIGURATION}`}
                    />
                </div>
            </div>
            <div className={css(styles.sidebarContentWrapper)}>
                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.LAYERS}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.LAYERS}
                    title="(Active) kaartlagen"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <MapLayerPane
                        mapLayers={mapLayers}
                        mapSources={mapSources}
                        onClickedOnMapLayer={props.clickedOnMapLayer}
                    />
                </SidebarPane>

                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.TRAFFIC_INFORMATION}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.TRAFFIC_INFORMATION}
                    title="Verkeersinformatie"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <TrafficMessagePane
                        trafficMessages={trafficMessages}
                        mapMovementAvailable={mapMovementAvailable}
                        onClickedOnSituationFeature={props.clickedOnSituationFeature}
                        isSortByValue={props.sortByValue}
                        isSortInDirection={props.sortInDirection}
                        onUpdateSortTrafficMessagesByValue={props.onUpdateSortTrafficMessagesByValue}
                        onUpdateSortTrafficMessagesInDirection={props.onUpdateTrafficMessagesSortingDirection}
                        currentTrafficCenter={props.currentTrafficCenter}
                    />
                </SidebarPane>

                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.CONFIGURATION}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.CONFIGURATION}
                    title="Uw NDW Viewer"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <UserSettingsPane
                        currentTrafficCenter={currentTrafficCenter}
                        currentThemeID={currentThemeID}
                        mapSettings={mapSettings}
                        trafficCenters={trafficCenters}
                        showRecordIds={showRecordIds}
                        showTrafficJamIcons={showTrafficJamIcons}
                        mapMovementAvailable={mapMovementAvailable}
                        changeCurrentTheme={props.changeCurrentTheme}
                        selectedNewTrafficCenter={props.selectedNewTrafficCenter}
                        setNewTravelTimeLineOffset={props.setNewTravelTimeLineOffset}
                        onToggleShowRecordIds={props.onToggleShowRecordIds}
                        onToggleShowTrafficJamIcons={props.onToggleShowTrafficJamIcons}
                        onToggleMapMovementAvailable={props.onToggleMapMovementAvailable}
                    />
                </SidebarPane>

                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.TRAJECTORY_SUMMED}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.TRAJECTORY_SUMMED}
                    title="Gesommeerde waarde trajecten"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <TrajectorySummedPane
                        trajectorySummedData={trajectorySummedData}
                        trajectorySummedSelection={trajectorySummedSelection}
                        trajectorySummedSelectionEnabled={trajectorySummedSelectionEnabled}
                        removeTrajectoryFromSelection={props.removeTrajectoryFromSelection}
                        toggleTrajectorySummedSelectionEnabled={props.toggleTrajectorySummedSelectionEnabled}
                    />
                </SidebarPane>

                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.OVERVIEW_ALL}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.OVERVIEW_ALL}
                    title="Overzichts tabellen configuratie"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <OverviewAllPane
                        currentVisibleTable={currentVisibleTable}
                        overviewTables={overviewAllTables()}
                        setNewVisibleOverviewAllTable={props.setNewVisibleOverviewAllTable}
                    />
                </SidebarPane>

                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.FILTERS}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.FILTERS}
                    title="Filter (kaart) gegevens"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <FilterPane
                        mapFilter={mapFilter}
                        mapLayers={mapLayers}
                        prefixes={prefixes}
                        currentSearchValue={currentSearchValue}
                        searchResults={searchResults}
                        changeSearchFieldValue={props.changeSearchFieldValue}
                        onFlyToLocation={props.onFlyToLocation}
                        setNewWazeAlertFilterStatusSet={props.setNewWazeAlertFilterStatusSet}
                        setNewWazeAlertNdwKnownFilterStatus={props.setNewWazeAlertNdwKnownFilterStatus}
                        setNewFdValidatedTrafficJamsFilterStatus={props.setNewFdVerifiedTrafficJamsFilterStatus}
                        setNewDfineFilterStatusOnlyRvmNetwork={props.setNewDfineFilterStatusOnlyRvmNetwork}
                        onSetNewPrefixFilter={props.onSetNewPrefixFilter}
                        setSituationTypeFilter={props.setSituationTypeFilter}
                        setNewRomoSizeFilter={props.setNewRomoSizeFilter}
                        setNewRomoSizeDeltaFilter={props.setNewRomoSizeDeltaFilter}
                        setNewRomoJerkFilter={props.setNewRomoJerkFilter}
                        setNewRomoJerkDeltaFilter={props.setNewRomoJerkDeltaFilter}
                        setNewRomoTimeFilter={props.setNewRomoTimeFilter}
                        setNewRomoRoadOperatorFilter={props.setNewRomoRoadOperatorFilter}
                        setRomoHWSelectedTypes={props.setRomoHWSelectedTypes}
                        setNewRomoHWDaysInHistoryFilter={props.setNewRomoHWDaysInHistoryFilter}
                    />
                </SidebarPane>

                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.RELEASE_NOTES}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.RELEASE_NOTES}
                    title="Versiegeschiedenis"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <ReleaseNotesPane
                        releaseNotes={releaseNote}
                    />
                </SidebarPane>

                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.LEGEND}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.LEGEND}
                    title="Legenda"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <LegendPane
                        legendData={legendData}
                    />
                </SidebarPane>

                <SidebarPane
                    identifier={`pane-${SidebarContentPanes.NOTIFICATION}`}
                    visible={sidebarVisibleTab === SidebarContentPanes.NOTIFICATION}
                    title="Gebruikersnotificatie"
                    onSidebarClose={() => props.onUpdateSidebarStatus(null)}
                >
                    <NotificationPane
                        onSaveNewNotificationMessage={props.updateNotificationMessage}
                    />
                </SidebarPane>
            </div>
        </div>;
    }

}

export default Sidebar;
