import React, {ReactElement} from 'react';
import StyledLabel from '../../../../../../components/StyledLabel';
import BooleanToggle from '../../../../../../components/BooleanToggle';
import {Props} from './Props';
import {mapLayer} from '../../../../../../models';
import Select from 'react-select';
import StyledInput from '../../../../../../components/StyledInput';
import {SearchFeature, SearchResult} from '../../../../../../interfaces/SearchFeature';
import SearchItem from './components/SearchItem';
import {PrefixFilterOption} from '../../../../../../interfaces/PrefixFilterOptions';
import ThemeContext from '../../../../../../contexts/ThemeContext';
import {selectStyles} from './styles';
import StyledSelect from '../../../../../../components/StyledSelect';
import RomoRoadOperatorOptions from './components/RomoRoadOperatorOptions';
import {TYPE_NAMES} from '../../../../../../services/RomoHWService';
import {HazardWarningType} from '../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesRomoHazardWarnings';

class FilterPane extends React.Component<Props> {
    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;
    private static readonly ROMO_HW_MIN_DAYS = 1;
    private static readonly ROMO_HW_MAX_DAYS = 31;

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {mapFilter, mapLayers, prefixes, currentSearchValue, searchResults} = props;
        const mapLayerValues = Object.values(mapLayers);

        return <React.Fragment>
            <p><i>Filter de actieve lagen met de beschikbare filter opties om zo de data set naar uw wens te
                krijgen.</i></p>
            <br/>
            {mapLayer.getLayerIsActive('trafficMessageOtis', mapLayerValues) && <React.Fragment>
                <h3>RWS verkeersberichten (OTIS)</h3>
                <StyledLabel title='OTIS: Verberg types'/>
                <Select
                    styles={selectStyles(this.context)}
                    isMulti={true}
                    options={mapFilter.situationTypeFilterOptions}
                    value={mapFilter.situationTypeFilters}
                    onChange={values => this.props.setSituationTypeFilter(Array.isArray(values) ? values : [])}
                />

            </React.Fragment>}

            {mapLayer.getLayerIsActive('wazeAlerts', mapLayerValues) && <React.Fragment>
                <h3>Meldingen weggebruikers incidenten (Gebeurtenissen)</h3>
                <StyledLabel title='Waze melding: Verberg NDW bekende berichten'/>
                <BooleanToggle
                    active={mapFilter.wazeAlertNdwKnown}
                    title='Verberg NDW bekende berichten'
                    changeStatus={() => props.setNewWazeAlertNdwKnownFilterStatus(!mapFilter.wazeAlertNdwKnown)}
                />

                <StyledLabel title='Waze melding: Verberg behandelde berichten'/>
                <BooleanToggle
                    active={mapFilter.wazeAlertStatusSet}
                    title='Verberg behandelde berichten'
                    changeStatus={() => props.setNewWazeAlertFilterStatusSet(!mapFilter.wazeAlertStatusSet)}
                />
                <hr/>
            </React.Fragment>}

            {mapLayer.getLayerIsActive('fd', mapLayerValues) && <React.Fragment>
                <h3>Meldingen weggebruikers incidenten (Gebeurtenissen)</h3>
                <StyledLabel title='Ongevalideerde files: Verberg files die in de gevalideerde files feed voorkomen'/>
                <BooleanToggle
                    active={mapFilter.fdVerifiedTrafficJams}
                    title='Verberg NDW bekende berichten'
                    changeStatus={() => props.setNewFdValidatedTrafficJamsFilterStatus(!mapFilter.fdVerifiedTrafficJams)}
                />
                <hr/>
            </React.Fragment>}

            {mapLayer.getLayerIsActive('dfine', mapLayerValues) && <React.Fragment>
                <h3>SRTI uit Sensordata (L3) (Voertuigdata)</h3>
                <StyledLabel title='SRTI uit Sensordata (L3): Alleen RVM netwerk meldingen weergeven'/>
                <BooleanToggle
                    active={mapFilter.dFineOnlyRvmNetwork}
                    title='Toon alleen RVM meldingen'
                    changeStatus={() => props.setNewDfineFilterStatusOnlyRvmNetwork(!mapFilter.dFineOnlyRvmNetwork)}
                />
                <hr/>
            </React.Fragment>}

            {mapLayer.getLayerIsActive('flowSpeed', mapLayerValues) && <React.Fragment>
                <h3>Snelheid en intensiteiten o.b.v. lussen (Algemeen verkeersbeeld)</h3>
                <StyledLabel title='Filter op prefixes'/>
                <Select
                    isMulti={true}
                    options={prefixes.flowSpeed}
                    value={mapFilter.prefixFilter.flowSpeed.prefixes
                        .map<PrefixFilterOption>((prefix) => ({value: prefix, label: prefix}))}
                    onChange={(values) =>
                        props.onSetNewPrefixFilter(
                            'flowSpeed',
                            Array.isArray(values) ? values.map((value: {
                                    value: string,
                                    label: string
                                }) => value.value) :
                                []
                        )}
                />
                <hr/>
            </React.Fragment>}
            {mapLayer.getLayerIsActive('travelTimeFcd', mapLayerValues) && <React.Fragment>
                <h3>Snelheid o.b.v. FCD Trajecten (Algemeen verkeersbeeld)</h3>
                <StyledLabel title='Filter op prefixes'/>
                <Select
                    isMulti={true}
                    options={prefixes.travelTimeFcd}
                    value={mapFilter.prefixFilter.travelTimeFcd.prefixes
                        .map<PrefixFilterOption>((prefix) => ({value: prefix, label: prefix}))}
                    onChange={(values) =>
                        props.onSetNewPrefixFilter(
                            'travelTimeFcd',
                            Array.isArray(values) ? values.map((value: {
                                    value: string,
                                    label: string
                                }) => value.value) :
                                []
                        )}
                />
                <hr/>
            </React.Fragment>}
            {mapLayer.getLayerIsActive('romoHazardWarnings', mapLayerValues) && <React.Fragment>
                <h3>Gevaren (ROMO)</h3>

                <StyledLabel title='Type'/>
                <Select
                    isMulti={true}
                    options={mapFilter.romoHW.types.map(type => ({value: type, label: TYPE_NAMES[type as HazardWarningType]}))}
                    value={mapFilter.romoHW.selectedTypes.map(type => ({value: type, label: TYPE_NAMES[type as HazardWarningType]}))}
                    onChange={values =>
                        props.setRomoHWSelectedTypes(Array.isArray(values) ? values.map((value: {
                            value: string,
                            label: string
                        }) => value.value) : [])
                    }
                />
                <StyledLabel title='Aantal dagen in het verleden'/>
                <StyledInput
                    type='range'
                    value={mapFilter.romoHW.maxDaysInHistory}
                    minValue={FilterPane.ROMO_HW_MIN_DAYS}
                    maxValue={FilterPane.ROMO_HW_MAX_DAYS}
                    changeInput={(maxDaysInHistory: string) => props.setNewRomoHWDaysInHistoryFilter(Number(maxDaysInHistory))}
                />
            </React.Fragment>}
            {mapLayer.getLayerIsActive('romoSurfaceDamages', mapLayerValues) && <React.Fragment>
                <h3>Wegbeschadigingen (ROMO)</h3>
                <StyledLabel title='Groter dan'/>
                <StyledInput
                    type='range'
                    value={mapFilter.romoSD.sizeFilter}
                    changeInput={(newValue: string) => props.setNewRomoSizeFilter(Number(newValue))}
                />
                <StyledLabel title='Grootte groter dan laatste update'/>
                <StyledInput
                    type='range'
                    value={mapFilter.romoSD.sizeDeltaFilter}
                    changeInput={(newValue: string) => props.setNewRomoSizeDeltaFilter(Number(newValue))}
                />
                <StyledLabel title='Schok groter dan'/>
                <StyledInput
                    type='range'
                    value={mapFilter.romoSD.jerkFilter}
                    changeInput={(newValue: string) => props.setNewRomoJerkFilter(Number(newValue))}
                />
                <StyledLabel title='Schok groter dan laatste update'/>
                <StyledInput
                    type='range'
                    value={mapFilter.romoSD.jerkDeltaFilter}
                    changeInput={(newValue: string) => props.setNewRomoJerkDeltaFilter(Number(newValue))}
                />
                <StyledLabel title='Aantal weken in het verleden'/>
                <StyledInput
                    type='range'
                    value={mapFilter.romoSD.timeFilter}
                    changeInput={(newValue: string) => props.setNewRomoTimeFilter(Number(newValue))}
                />
            </React.Fragment>}
            {(mapLayer.getLayerIsActive('romoSurfaceDamages', mapLayerValues) || mapLayer.getLayerIsActive('romoHazardWarnings', mapLayerValues)) && <React.Fragment>
                <h3>ROMO</h3>
                <StyledLabel title='Wegbeheerder' />
                <StyledSelect
                    value={mapFilter.romo.roadOperatorId}
                    onChange={e => props.setNewRomoRoadOperatorFilter(e.target.value)}>
                    <RomoRoadOperatorOptions />
                </StyledSelect>
            </React.Fragment>}
            <StyledLabel title='Uw zoekopdracht'/>
            <StyledInput
                type='text'
                value={currentSearchValue}
                changeInput={(newValue: string) => this.handleSearchInputChange(newValue)}
            />

            {searchResults && searchResults.searchResults.map((searchResult: SearchResult, index: number) =>
                <React.Fragment key={index}>
                    <p><strong>{searchResult.name}</strong></p>
                    {searchResult.results.map((searchFeature: SearchFeature, resultIndex: number) => <SearchItem
                        currentSearchValue={currentSearchValue}
                        searchFeature={searchFeature}
                        clickedOnItem={this.clickedOnFeature}
                        key={resultIndex}
                    />)}
                    {searchResult.results.length === 0 && <p>Geen resultaten voor deze data stroom</p>}
                    {searchResult.results.length === 150 &&
                        <p><strong><i>Meer dan 150 resultaten, verfijn uw zoekopdracht voor betere
                            resultaten</i></strong>
                        </p>}
                    <br/>
                </React.Fragment>)}

            {(searchResults && searchResults.searchResults.length === 0) &&
                <p>Geen resultaten gevonden</p>}
        </React.Fragment>;
    }

    private handleSearchInputChange = (e: string) => {
        this.props.changeSearchFieldValue(e);
    };

    private clickedOnFeature = (item: SearchFeature) => {
        if (!item.coordinates.latitude || !item.coordinates.longitude) {
            return;
        }

        this.props.onFlyToLocation(item.coordinates.latitude, item.coordinates.longitude, 15);
    };

}

export default FilterPane;
