import {MapSceneReleaseNotesActionTypes, mapSceneReleaseNotesSetNewReleaseNotes} from '../actions/reducers/releaseNotes';
import {ReleaseNoteDTO} from '../../../generated/ViewerApiClient';
import {getType} from 'typesafe-actions';
import {Reducer} from 'redux';

export interface MapSceneReleaseNotesReducerState {
    releaseNotes: ReleaseNoteDTO[];
}

const initialState: MapSceneReleaseNotesReducerState = {
    releaseNotes: []
};

const releaseNotesReducer: Reducer<MapSceneReleaseNotesReducerState, MapSceneReleaseNotesActionTypes> =
    (state: MapSceneReleaseNotesReducerState = initialState, action: MapSceneReleaseNotesActionTypes) => {
        switch (action.type) {
            case getType(mapSceneReleaseNotesSetNewReleaseNotes):
                return {
                    ...state,
                    releaseNotes: action.payload.releaseNotes
                };
            default:
                return {
                    ...state
                };
        }
    };

export default releaseNotesReducer;
