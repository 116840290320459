import {Reducer} from 'redux';
import {
    ActiveDialogGeoJsonFeatureWazeAlertIncident,
    DIALOG_INTERFACES,
    DialogTypes,
    GeoJsonSubTypes,
    VectorLayerSubTypes
} from '../../../interfaces/ActiveDialog';
import {
    changeWazeAlertIncidentStatusValue,
    closeCurrentDialog,
    closeHighestIndexDialog,
    makeDialogProminent,
    mapSceneActiveDialogAddNewDialog,
    MapSceneActiveDialogReducerActionTypes,
    mapSceneActiveDialogUpdateSegmentHeatMapData,
    removePopupsWithMapSource,
    removeUnpinnedActiveDialogs,
    setNewWazeAlertSavingStatus,
    storeHazardWarningHistory,
    storeNewBridgeHistoricalGraphData,
    storeNewGraphData,
    storeSurfaceDamageHistory,
    updateActiveDialogCountUnpinned,
    updateActiveDialogPinStatus,
    updateWazeAlertDialogTimeRemaining,
    updateWazeAlertIncidentDialogStatusData
} from '../actions/reducers/activeDialogs';
import {ActionType, getType} from 'typesafe-actions';
import update from 'immutability-helper';
import _ from 'lodash';
import {GeoJsonPropertiesBridge} from '../../../interfaces/GeoJsonSources/GeoJsonPropertiesBridge';
import {WAZE_ALERT_ITEM_SUB_TYPES} from '../../../interfaces/WazeAlertItemStatus';
import {FrontOfficeEventStatus} from '../../../constants';
import {
    GeoJsonPropertiesRomoSurfaceDamages
} from '../../../interfaces/GeoJsonSources/GeoJsonPropertiesRomoSurfaceDamages';

export interface MapSceneActiveDialogsReducerState {
    activeDialogs: DIALOG_INTERFACES[];
    currentHighestPopupZIndex: number;
    numberOfUnpinnedDialogs: number;
}

const initialState: MapSceneActiveDialogsReducerState = {
    activeDialogs: [],
    currentHighestPopupZIndex: 100,
    numberOfUnpinnedDialogs: 0
};

const findDialogIndexWithSubSubtype = (activeDialogs: DIALOG_INTERFACES[], id: string, subType: GeoJsonSubTypes): number => {
    return _.findIndex(activeDialogs, (item: DIALOG_INTERFACES) => {
        if (!item) {
            return false;
        }
        return item.type === DialogTypes.GEO_JSON && item.subType === subType && item.id === id;
    });
};


const getStateForHazardWarning: (
    state: MapSceneActiveDialogsReducerState,
    action: ActionType<typeof storeHazardWarningHistory>
) => MapSceneActiveDialogsReducerState = (state, action) => {
    const {id: hazardWarningId, history: hazardWarningHistory} = action.payload;

    const activeDialogIndex = state.activeDialogs.findIndex((item) => {
        return (
            item.type === DialogTypes.GEO_JSON &&
            item.subType === GeoJsonSubTypes.HAZARD_WARNING &&
            item.id === hazardWarningId
        );
    });

    if (activeDialogIndex < 0) {
        return state;
    }
    return update(state, {
        activeDialogs: {
            [activeDialogIndex]: {
                $merge: {
                    history: hazardWarningHistory
                }
            }
        }
    });
};

const activeDialogReducer: Reducer<MapSceneActiveDialogsReducerState, MapSceneActiveDialogReducerActionTypes> = (
    state: MapSceneActiveDialogsReducerState = initialState, action: MapSceneActiveDialogReducerActionTypes) => {
    switch (action.type) {
        case getType(mapSceneActiveDialogAddNewDialog):
            const removeDialogsWithIndex: string[] = state.activeDialogs
                .filter(dialog => !dialog.pinned && dialog.preview)
                .map((_dialog: DIALOG_INTERFACES, indexDialog: number) => indexDialog.toString());
            return update(
                state,
                {
                    activeDialogs: {
                        // @ts-ignore
                        $push: [action.payload.dialog],
                        // @ts-ignore
                        $unset: removeDialogsWithIndex
                    },
                    currentHighestPopupZIndex: {
                        $set: action.payload.dialog.zIndex
                    }
                }
            );
        case getType(closeCurrentDialog):
            return {
                ...state,
                activeDialogs: state.activeDialogs.filter((item: DIALOG_INTERFACES) => item.id !== action.payload.id)
            };
        case getType(closeHighestIndexDialog):
            let highestZindex = -1;
            let keyToRemove = -1;
            state.activeDialogs.forEach((item: DIALOG_INTERFACES, indexCloseHighestDialog: number) => {
                if (item.zIndex > highestZindex) {
                    highestZindex = item.zIndex;
                    keyToRemove = indexCloseHighestDialog;
                }
            });

            if (keyToRemove === -1) {
                return state;
            }

            const unsetArray: string[] = [keyToRemove.toString()];
            return update(
                state,
                {
                    activeDialogs: {
                        // @ts-ignore
                        $unset: unsetArray
                    }
                }
            );
        case getType(makeDialogProminent):
            const newZindex = state.currentHighestPopupZIndex + 1;
            return update(
                state,
                {
                    activeDialogs: {
                        [action.payload.index]: {
                            $merge: {
                                zIndex: newZindex,
                                preview: action.payload.preview
                            }
                        }
                    },
                    currentHighestPopupZIndex: {
                        $set: newZindex
                    }
                }
            );
        case getType(removePopupsWithMapSource):

            return update(
                state,
                {
                    activeDialogs: (arr: DIALOG_INTERFACES[]) => arr.filter((item: DIALOG_INTERFACES) => {
                        if (item.type === DialogTypes.GEO_JSON) {
                            if (item.dataSource === action.payload.mapSourceID) {
                                return false;
                            }
                        } else if (item.type === DialogTypes.VECTOR_LAYER) {
                            if (item.dataSource === action.payload.mapSourceID) {
                                return false;
                            }
                        } else if (item.type === DialogTypes.SELECT_FEATURES) {
                            for(const featuresKey in item.features) {
                                if (!item.features.hasOwnProperty(featuresKey)) {
                                    continue;
                                }

                                const feature = item.features[featuresKey];

                                if (feature.source === action.payload.mapSourceID) {
                                    return false;
                                }
                            }
                        }

                        return true;
                    })
                }
            );
        case getType(removeUnpinnedActiveDialogs):
            return update(
                state,
                {
                    activeDialogs: (arr: DIALOG_INTERFACES[]) =>
                        arr.filter((item: DIALOG_INTERFACES) => item.pinned)
                }
            );
        case getType(storeNewBridgeHistoricalGraphData):
            const indexBridgeDialog = _.findIndex(state.activeDialogs, (item: DIALOG_INTERFACES) => {
                if (!item) {
                    return false;
                }

                if (item.type === DialogTypes.GEO_JSON && item.subType === GeoJsonSubTypes.BRIDGE) {
                    const properties = item.currentProperties as GeoJsonPropertiesBridge;
                    return properties.risIndex === action.payload.risIndex
                        && properties.vild === action.payload.vild;
                }

                return false;
            });

            if (indexBridgeDialog === -1) {
                return state;
            }

            return update(
                state,
                {
                    activeDialogs: {
                        [indexBridgeDialog]: {
                            $merge: {
                                bridgeHistory: action.payload.bridgeHistoryData
                            }
                        }
                    }
                }
            );
        case getType(storeSurfaceDamageHistory):
            const indexSurfaceDamageHistoryDialog = _.findIndex(state.activeDialogs, (item: DIALOG_INTERFACES) => {
                if (!item) {
                    return false;
                }

                if (item.type === DialogTypes.GEO_JSON && item.subType === GeoJsonSubTypes.SURFACE_DAMAGE) {
                    const properties = item.currentProperties as GeoJsonPropertiesRomoSurfaceDamages;
                    return properties.id === action.payload.id;
                }

                return false;
            });

            if (indexSurfaceDamageHistoryDialog === -1) {
                return state;
            }

            return update(
                state,
                {
                    activeDialogs: {
                        [indexSurfaceDamageHistoryDialog]: {
                            $merge: {
                                history: action.payload.history
                            }
                        }
                    }
                }
            );
        case getType(storeHazardWarningHistory):
            return getStateForHazardWarning(state, action);
        case getType(storeNewGraphData):

            const index = _.findIndex(state.activeDialogs, (item: DIALOG_INTERFACES) => {
                if (!item) {
                    return false;
                }

                if (
                    item.type === DialogTypes.VECTOR_LAYER &&
                    (item.subType === VectorLayerSubTypes.TRAVEL_TIME_FCD || item.subType === VectorLayerSubTypes.TRAVEL_TIME_DRIPS || item.subType === VectorLayerSubTypes.TRAVEL_TIME_OTHER)
                ) {
                    return item.id === action.payload.featureID;
                } else if (item.type === DialogTypes.GEO_JSON && item.subType === GeoJsonSubTypes.FLOW_SPEED) {
                    return item.id === action.payload.featureID;
                }

                return false;
            });

            if (index === -1) {
                return state;
            }

            return update(
                state,
                {
                    // @ts-ignore
                    activeDialogs: {
                        [index]: {
                            $merge: {
                                graphs: action.payload.graphData
                            }
                        }
                    }
                }
            );
        case getType(mapSceneActiveDialogUpdateSegmentHeatMapData):
            const updateSegmentHeatMapIndex = _.findIndex(state.activeDialogs, (item: DIALOG_INTERFACES) => {
                if (!item) {
                    return false;
                }

                if (item.type === DialogTypes.VECTOR_LAYER && item.subType === VectorLayerSubTypes.TRAVEL_TIME_FCD) {
                    return item.id === action.payload.featureKey;
                }

                return false;
            });

            if (updateSegmentHeatMapIndex === -1) {
                return state;
            }

            return update(
                state,
                {
                    // @ts-ignore
                    activeDialogs: {
                        [updateSegmentHeatMapIndex]: {
                            $merge: {
                                segmentHeatMap: action.payload.segmentHeatMapData
                            }
                        }
                    }
                }
            );
        case getType(updateActiveDialogCountUnpinned):
            let newCount = 0;
            state.activeDialogs.forEach((item: DIALOG_INTERFACES) => {
                if (!item.pinned) {
                    newCount++;
                }
            });
            return {
                ...state,
                numberOfUnpinnedDialogs: newCount
            };
        case getType(updateActiveDialogPinStatus):
            return update(
                state,
                {
                    activeDialogs: {
                        [action.payload.index]: {
                            $merge: {
                                pinned: action.payload.newStatus
                            }
                        }
                    }
                }
            );
        case getType(changeWazeAlertIncidentStatusValue):
            const indexUpdateWazeAlertIncidentStatus = findDialogIndexWithSubSubtype(
                state.activeDialogs,
                action.payload.id,
                GeoJsonSubTypes.WAZE_ALERT_INCIDENT
            );

            if (indexUpdateWazeAlertIncidentStatus === -1) {
                return state;
            }

            const updateObject: {
                lastChangeTimestamp: number;
                savingStatus: null;
                status?: FrontOfficeEventStatus;
                hectometrePost?: string;
                newSubType?: WAZE_ALERT_ITEM_SUB_TYPES;
            } = {
                lastChangeTimestamp: Date.now(),
                savingStatus: null
            };
            if (action.payload.newStatus) {
                updateObject.status = action.payload.newStatus;
            }
            if (typeof action.payload.hectometrePost === 'string') {
                updateObject.hectometrePost = action.payload.hectometrePost;
            }

            if (action.payload.subType) {
                updateObject.newSubType = action.payload.subType;
            }

            return update(
                state,
                {
                    activeDialogs: {
                        [indexUpdateWazeAlertIncidentStatus]: {
                            $merge: updateObject
                        }
                    }
                }
            );
        case getType(setNewWazeAlertSavingStatus):
            const indexSetNewWazeAlertSavingStatus = findDialogIndexWithSubSubtype(
                state.activeDialogs,
                action.payload.id,
                GeoJsonSubTypes.WAZE_ALERT_INCIDENT
            );

            if (indexSetNewWazeAlertSavingStatus === -1) {
                return state;
            }

            const dialog = state.activeDialogs[indexSetNewWazeAlertSavingStatus] as ActiveDialogGeoJsonFeatureWazeAlertIncident;

            return update(
                state,
                {
                    activeDialogs: {
                        [indexSetNewWazeAlertSavingStatus]: {
                            $merge: {
                                lastSaveTimestamp: action.payload.savingStatus === 'CHANGES_SAVED' ?
                                    dialog.lastChangeTimestamp :
                                    dialog.lastSaveTimestamp,
                                savingStatus: action.payload.savingStatus
                            }
                        }
                    }
                }
            );

        case getType(updateWazeAlertDialogTimeRemaining):
            const indexUpdateWazeAlertDialogTimeRemaining = findDialogIndexWithSubSubtype(
                state.activeDialogs,
                action.payload.id,
                GeoJsonSubTypes.WAZE_ALERT_INCIDENT
            );

            if (indexUpdateWazeAlertDialogTimeRemaining === -1) {
                return state;
            }

            return update(
                state,
                {
                    activeDialogs: {
                        [indexUpdateWazeAlertDialogTimeRemaining]: {
                            $merge: {
                                timeRemainingBeforeAutomaticClosure: action.payload.newValue
                            }
                        }
                    }
                }
            );
        case getType(updateWazeAlertIncidentDialogStatusData):
            const updateWazeAlertIncidentDialogStatusIndex = state.activeDialogs.findIndex((item: DIALOG_INTERFACES | undefined) =>
                item && item.type === DialogTypes.GEO_JSON && item.subType === GeoJsonSubTypes.WAZE_ALERT_INCIDENT
                && item.currentProperties.id === action.payload.id);

            if (updateWazeAlertIncidentDialogStatusIndex === -1) {
                return state;
            }

            return update(
                state,
                {
                    activeDialogs: {
                        [updateWazeAlertIncidentDialogStatusIndex]: {
                            $merge: {
                                hasLoadedData: action.payload.hasLoadedData,
                                hectometrePost: action.payload.hectometrePost,
                                isOpen: action.payload.isOpen,
                                loadingDataFailed: action.payload.loadingDataHasFailed,
                                status: action.payload.status as FrontOfficeEventStatus
                            }
                        }
                    }
                }
            );
        default:
            return state;
    }
};

export default activeDialogReducer;
