import React from 'react';
import {roadOperators, roadOperatorTypeLabels} from '../../../../../../../constants';

const RomoRoadOperatorOptions: React.FunctionComponent = () => {
    return (
        <>
            <option value="">Alle</option>
            {roadOperators.map(({id, type, name}) => (
                <option key={id} value={id}>
                    {name} ({roadOperatorTypeLabels[type]})
                </option>
            ))}
        </>
    );
};

export default RomoRoadOperatorOptions;
