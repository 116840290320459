import {catchError, map} from 'rxjs/operators';
import {combineLatest, EMPTY, from, Observable, of} from 'rxjs';
import {BACKEND_BASE_URL, BACKEND_V2_BASE_URL, ENVIRONMENT} from '../../applicationContext';
import ViewerApiClient, {
    LayerDTO,
    MapLayerDTO,
    NotificationDTO,
    SurfaceDamageDTO
} from '../../generated/ViewerApiClient';
import {DATA_SOURCE_STATUS_DEACTIVATED, DataSource} from '../../interfaces/DataSource';
import {keycloakService} from '@ndw/react-keycloak-authentication';
import {
    viewerApiServiceReceivedWazeStatusData,
    viewerApiServiceReceivingWazeStatusDataFailedWithBadRequestError,
    viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError,
    viewerApiServiceReceivingWazeStatusDataFailedWithUnexpectedError,
    viewerApiServiceSuccessFullyUpdatedWazeStatusData,
    viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError,
    viewerApiServiceUpdatingWazeStatusUnexpectedError,
    WazeStatusDataActionTypes
} from './actions/wazeStatusData';
import {
    viewerApiServiceLoadingTrafficCentersDataUnauthorizedError,
    viewerApiServiceLoadingTrafficCentersDataUnexpectedError,
    viewerApiServiceReceivedTrafficCentersData,
    ViewerApiServiceTrafficCenterDataActionTypes
} from './actions/trafficCentersData';
import {
    ReleaseNotesDataActionTypes,
    viewerApiServiceLoadingReleaseNotesUnexpectedError,
    viewerApiServiceReceivedReleaseNotesData
} from './actions/releaseNotesData';
import {
    LegendDataActionTypes,
    viewerApiServiceReceivedLegendDate,
    viewerApiServiceReceivingLegendDataUnexpectedError
} from './actions/legendData';
import {
    LayerConfigurationDataActionTypes,
    viewerApiServiceReceivedLayerConfiguration,
    viewerApiServiceReceivingLayerConfigurationUnexpectedError
} from './actions/layerConfigurationData';
import {
    SourceDataDataActionTypes,
    viewerApiServiceReceivedSourceData,
    viewerApiServiceReceivedSourceDataWithNoUpdate,
    viewerApiServiceReceivingSourceDataFailedWithBadRequest,
    viewerApiServiceReceivingSourceDataFailedWithNotFoundError,
    viewerApiServiceReceivingSourceDataFailedWithUnauthorizedError,
    viewerApiServiceReceivingSourceDataUnexpectedError
} from './actions/sourceDataData';
import {
    HistoricalSummedDataDataActionTypes,
    viewerApiServiceReceivedHistoricalSummedData,
    viewerApiServiceReceivingHistoricalSummedDataFailedWithUnauthorizedError,
    viewerApiServiceReceivingHistoricalSummedDataFailedWithUnexpectedError
} from './actions/historicalSummedDataData';
import {
    HistoricalDataDataActionTypes,
    viewerApiServiceReceivedHistoricalData,
    viewerApiServiceReceivingHistoricalDataFailedWithUnauthorizedError,
    viewerApiServiceReceivingHistoricalDataFailedWithUnexpectedError
} from './actions/historicalDataData';
import {
    viewerApiServiceReceivedWazeStatusCloseSuccessful,
    viewerApiServiceReceivingWazeStatusCloseFailedWithBadRequestResponse,
    viewerApiServiceReceivingWazeStatusCloseFailedWithUnauthorizedError,
    viewerApiServiceReceivingWazeStatusCloseFailedWithUnexpectedError,
    WazeStatusCloseDataActionTypes
} from './actions/wazeStatusCloseData';
import {
    NotificationPaneActionTypes,
    viewerApiServiceReceivedNotificationMessage,
    viewerApiServiceReceivingNotificationMessageFailedWithNotFoundError,
    viewerApiServiceReceivingNotificationMessageFailedWithUnauthorizedError,
    viewerApiServiceReceivingNotificationMessageFailedWithUnexpectedError,
    viewerApiServiceSavedNotificationMessage,
    viewerApiServiceSavingNotificationFailedWithUnauthorizedException,
    viewerApiServiceSavingNotificationFailedWithUnexpectedError,
    viewerApiServiceSavingNotificationMessageFailedWithBadRequestException
} from './actions/notificationPane';
import {
    AccessDataActionTypes,
    viewerApiServiceReceivedAccessData,
    viewerApiServiceReceivingAccessDataFailed
} from './actions/accessData';
import {
    viewerApiServiceUpdatedWazeOpen,
    viewerApiServiceUpdatingWazeOpenFailedWithBadRequestExceptionError,
    viewerApiServiceUpdatingWazeOpenFailedWithUnauthorizedExceptionError,
    viewerApiServiceUpdatingWazeOpenFailedWithUnexpectedError,
    WazeOpenActionTypes
} from './actions/wazeOpen';
import {WAZE_ALERT_ITEM_SUB_TYPES, WAZE_ALERT_ITEM_TYPES} from '../../interfaces/WazeAlertItemStatus';
import {
    LoadSearchFeaturesDataActionTypes,
    viewerApiServiceReceivedSearchFeaturesData,
    viewerApiServiceReceivingSearchFeaturesDataFailedWithBadRequestError,
    viewerApiServiceReceivingSearchFeaturesDataFailedWithNotFoundError,
    viewerApiServiceReceivingSearchFeaturesDataFailedWithUnauthorizedError,
    viewerApiServiceReceivingSearchFeaturesDataFailedWithUnexpectedError
} from './actions/loadSearchFeaturesData';
import {
    viewApiServiceLoadPrefixDataCompleted,
    ViewerApiServiceLoadPrefixData,
    viewerApiServiceLoadPrefixDataFailedWithUnauthorizedError,
    viewerApiServiceLoadPrefixDataFailedWithUnexpectedError
} from './actions/loadPrefixData';
import {
    LoadBridgeHistoryActionTypes,
    viewerApiServiceReceivedBridgeHistory,
    viewerApiServiceReceivingBridgeHistoryFailedWithUnauthorizedError,
    viewerApiServiceReceivingBridgeHistoryFailedWithUnexpectedError
} from './actions/loadBridgeHistory';
import {FrontOfficeEventStatus} from '../../constants';
import {
    ViewerApiServiceSegmentHeatMapActionTypes,
    viewerApiServiceSegmentHeatMapLoadedData,
    viewerApiServiceSegmentHeatmapLoadingDataFailedWithBadRequestException,
    viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnauthorizedException,
    viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnexpectedError
} from './actions/segmentHeatMap';
import {
    ApiError,
    DataSourceConfig,
    HazardWarningHistoryDto,
    ViewerApiClientV2
} from '../../generated/ViewerApiV2Client';
import {
    loadedFilterDataRomoHW,
    loadedFilterDataRomoHWFailed
} from '../../scenes/MapScene/actions/reducers/filterAndSearch';

const defaultApiFactory = (): ViewerApiClient => {
    const viewerApiClient = new ViewerApiClient(`${BACKEND_BASE_URL}/api/v1`);

    viewerApiClient.setRequestHeadersHandler(() => {
        if (ENVIRONMENT === 'DEV') {
            return {Authorization: 'DEV'};
        }

        if (!keycloakService.keycloakInstance && keycloakService!.keycloakInstance!.token) {
            throw new Error('Keycloak instance or token not defined');
        }

        return {Authorization: `Bearer ${keycloakService.keycloakInstance!.token!}`};
    });

    viewerApiClient.setConfigureRequestHandler((request) => request.timeout(15000).ok((response) => {
        response.ok = true;
        return true;
    }));

    return viewerApiClient;
};

const viewerApiClientV2 = (): ViewerApiClientV2 => {
    if (!keycloakService.keycloakInstance !== null && !keycloakService.keycloakInstance?.token && ENVIRONMENT !== 'DEV') {
        throw new Error('Keycloak instance or token not defined');
    }
    const token = ENVIRONMENT === 'DEV'? '':keycloakService.keycloakInstance!.token;
    return new ViewerApiClientV2({BASE: BACKEND_V2_BASE_URL, TOKEN: token});
};
export const loadLayerConfiguration = (): Observable<LayerConfigurationDataActionTypes> => {
    return combineLatest(
        [from(viewerApiClientV2().mapLayerController.getMapLayers()),
            from(viewerApiClientV2().dataSourceController.getDataSources())]
    ).pipe(
        map(([mapLayerConfigs, dataSourceConfigs]) => {
            const maplayerDtos: MapLayerDTO[] = mapLayerConfigs.map(mapLayerConfig => ({
                id: mapLayerConfig.id,
                name: mapLayerConfig.name,
                category: mapLayerConfig.category,
                description: mapLayerConfig.description,
                explanation: mapLayerConfig.explanation,
                source: mapLayerConfig.source,
                layers: JSON.stringify(mapLayerConfig.layers),
                zIndex: mapLayerConfig.zindex || 0
            }));

            const datasources: DataSource[] = dataSourceConfigs.map(dataSourceConfig => {
                return {
                    id: dataSourceConfig.id,
                    name: dataSourceConfig.name,
                    loadBaseData: dataSourceConfig.loadBaseData || false,
                    refreshUpdate: dataSourceConfig.refreshUpdate || false,
                    dataLoadMethod: dataSourceConfig.dataLoadMethod === DataSourceConfig.dataLoadMethod.GEO_JSON ? 'GeoJSON' : 'VectorTiles',
                    updateInterval: dataSourceConfig.updateInterval || 60000,
                    sourceLayer: dataSourceConfig.sourceLayer || '',
                    sourceConfiguration: dataSourceConfig.sourceConfiguration,
                    searchProperty: dataSourceConfig.searchProperty,
                    websocketReload: dataSourceConfig.websocketReload || false,
                    websocketUrl: dataSourceConfig.websocketUrl || '',
                    colorBlindSupported: dataSourceConfig.colorBlindSupported || false,
                    streamable: dataSourceConfig.streamable,
                    currentIdentifier: null,
                    isActive: false,
                    lastUpdateReceived: null,
                    layerFilteredMapData: null,
                    layerMapData: null,
                    searchFeatures: [],
                    sourceUpdateIdentifier: null,
                    status: DATA_SOURCE_STATUS_DEACTIVATED,
                    lastSnapshotUpdateTimestamp: null,
                    incrementStreamName: dataSourceConfig.incrementStreamName
                } as unknown as DataSource;
            });
            return viewerApiServiceReceivedLayerConfiguration(maplayerDtos, datasources);
        }),
        catchError(() => of(viewerApiServiceReceivingLayerConfigurationUnexpectedError()))
    );
};

export const loadSourceData = (dataSource: DataSource, layerType: string, colorBlind: boolean): Observable<SourceDataDataActionTypes> => {
    const promise = dataSource.streamable
        ? viewerApiClientV2().snapshotController.getSnapshotById(dataSource.id).then(data => ({body: data as LayerDTO, status: 200}))
        : defaultApiFactory().getLayer({
            id: dataSource.id as LayerDTO['id'],
            layerType: layerType as LayerDTO['type'],
            colorBlind: colorBlind
        });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedSourceData(response.body, dataSource, layerType);
                    case 304:
                        return viewerApiServiceReceivedSourceDataWithNoUpdate();
                    case 400:
                        return viewerApiServiceReceivingSourceDataFailedWithBadRequest();
                    case 403:
                        return viewerApiServiceReceivingSourceDataFailedWithUnauthorizedError();
                    case 404:
                        return viewerApiServiceReceivingSourceDataFailedWithNotFoundError(dataSource.id, dataSource.name, layerType);
                    case 500:
                    default:
                        return viewerApiServiceReceivingSourceDataUnexpectedError(response.status, dataSource.id, dataSource.name, layerType);
                }
            }),
            catchError(() => of(viewerApiServiceReceivingSourceDataUnexpectedError(-1, dataSource.id, dataSource.name, layerType)))
        );
};

export const loadHistoricalSummedData = (featureIDs: string[]): Observable<HistoricalSummedDataDataActionTypes> => {
    const promise = defaultApiFactory().getHistoricalSummed({
        dataType: 'travelTime',
        ids: featureIDs
    });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedHistoricalSummedData(response.body);
                    case 403:
                        return viewerApiServiceReceivingHistoricalSummedDataFailedWithUnauthorizedError();
                    case 400:
                    case 500:
                    default:
                        return viewerApiServiceReceivingHistoricalSummedDataFailedWithUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceReceivingHistoricalSummedDataFailedWithUnexpectedError()))
        );
};

export const loadHistoricalData = (
    dateType: 'flowSpeed' | 'travelTime',
    mapSourceID: string,
    featureID: number | string,
    locationID: string,
    timestamp: number
): Observable<HistoricalDataDataActionTypes> => {
    const promise = defaultApiFactory().getHistorical({
        dataType: dateType,
        id: locationID,
        timestamp: timestamp.toString()
    });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedHistoricalData(
                            dateType,
                            mapSourceID,
                            featureID,
                            locationID,
                            timestamp,
                            response.body
                        );
                    case 403:
                        return viewerApiServiceReceivingHistoricalDataFailedWithUnauthorizedError();
                    case 500:
                    case 400:
                    default:
                        return viewerApiServiceReceivingHistoricalDataFailedWithUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceReceivingHistoricalDataFailedWithUnexpectedError()))
        );
};

export const loadBridgeHistory = (risIndex: string, vild: string): Observable<LoadBridgeHistoryActionTypes> => {
    const promise = defaultApiFactory().getBridgeHistory({
        risIndex,
        vild
    });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedBridgeHistory(risIndex, vild, response.body);
                    case 403:
                        return viewerApiServiceReceivingBridgeHistoryFailedWithUnauthorizedError();
                    case 400:
                    case 500:
                    default:
                        return viewerApiServiceReceivingBridgeHistoryFailedWithUnexpectedError();
                }
            })
        );
};

export const fetchSurfaceDamageHistory = (id: number): Observable<SurfaceDamageDTO[]> => {
    const promise = defaultApiFactory().getRomoHistory({surfaceDamageId: id});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return response.body;
                    case 403:
                        throw new UnauthorizedError();
                    case 400:
                    case 500:
                    default:
                        throw new UnexpectedError();
                }
            })
        );
};


export const fetchHazardWarningHistory = (id: string): Observable<HazardWarningHistoryDto[]> => {
    return from(viewerApiClientV2().hazardWarningController.getHistoryForHazardWarningId(id)).pipe(
        catchError((error) => {
            if (error instanceof ApiError && [401, 403].includes(error.status)) {
                throw new UnauthorizedError();
            }
            throw new UnexpectedError();
        })
    );
};

export const setWazeOpen = (id: string): Observable<WazeOpenActionTypes> => {
    const promise = defaultApiFactory().postWazeOpen({
        id
    });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 204:
                        return viewerApiServiceUpdatedWazeOpen();
                    case 400:
                        return viewerApiServiceUpdatingWazeOpenFailedWithBadRequestExceptionError();
                    case 403:
                        return viewerApiServiceUpdatingWazeOpenFailedWithUnauthorizedExceptionError();
                    case 500:
                        return viewerApiServiceUpdatingWazeOpenFailedWithUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceUpdatingWazeOpenFailedWithUnexpectedError()))
        );
};

export const loadWazeStatus = (id: string): Observable<WazeStatusDataActionTypes> => {
    const promise = defaultApiFactory().getWazeStatus({
        $deadline: 60000,
        $timeout: 5000,
        id: id
    });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedWazeStatusData(id, response.body);
                    case 400:
                        return viewerApiServiceReceivingWazeStatusDataFailedWithBadRequestError(id);
                    case 403:
                        return viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError(id);
                    case 500:
                    default:
                        return viewerApiServiceReceivingWazeStatusDataFailedWithUnexpectedError(id);
                }
            }),
            catchError(() => of(viewerApiServiceReceivingWazeStatusDataFailedWithUnexpectedError(id)))
        );

};

export const updateWazeStatus = (
    id: string,
    name: string,
    status: FrontOfficeEventStatus | undefined,
    hectometrePost: string | undefined,
    type: WAZE_ALERT_ITEM_TYPES | undefined,
    subType: WAZE_ALERT_ITEM_SUB_TYPES | undefined
): Observable<WazeStatusDataActionTypes> => {
    const finalStatus: FrontOfficeEventStatus | undefined = (status === FrontOfficeEventStatus.none ? undefined : status) || undefined;
    const promise = defaultApiFactory().postWazeUpdate({
        id: id,
        location: hectometrePost,
        status: finalStatus,
        subType: subType,
        type: type
    });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 204:
                        return viewerApiServiceSuccessFullyUpdatedWazeStatusData(id, name);
                    case 403:
                        return viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError(id, name);
                    case 400:
                    case 500:
                    default:
                        return viewerApiServiceUpdatingWazeStatusUnexpectedError(id, name);
                }
            }),
            catchError(() => of(viewerApiServiceUpdatingWazeStatusUnexpectedError(id, name)))
        );
};

export const updateWazeStatusClose = (id: string): Observable<WazeStatusCloseDataActionTypes> => {
    const promise = defaultApiFactory().postWazeClose({
        id
    });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 204:
                        return viewerApiServiceReceivedWazeStatusCloseSuccessful(id);
                    case 400:
                        return viewerApiServiceReceivingWazeStatusCloseFailedWithBadRequestResponse();
                    case 403:
                        return viewerApiServiceReceivingWazeStatusCloseFailedWithUnauthorizedError();
                    case 500:
                    default:
                        return viewerApiServiceReceivingWazeStatusCloseFailedWithUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceReceivingWazeStatusCloseFailedWithUnexpectedError()))
        );
};

export const loadTrafficCentersData = (): Observable<ViewerApiServiceTrafficCenterDataActionTypes> => {
    const promise = defaultApiFactory().getTrafficCenter({});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedTrafficCentersData(response.body);
                    case 403:
                        return viewerApiServiceLoadingTrafficCentersDataUnauthorizedError();
                    case 500:
                    default:
                        return viewerApiServiceLoadingTrafficCentersDataUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceLoadingTrafficCentersDataUnexpectedError()))
        );
};

export const loadReleaseNotesData = (): Observable<ReleaseNotesDataActionTypes> => {
    const promise = defaultApiFactory().getReleaseNotes({});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedReleaseNotesData(response.body);
                    case 500:
                    default:
                        return viewerApiServiceLoadingReleaseNotesUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceLoadingReleaseNotesUnexpectedError()))
        );
};

export const loadLegendData = (): Observable<LegendDataActionTypes> => {
    const promise = defaultApiFactory().getLegend({});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedLegendDate(response.body);
                    case 500:
                    default:
                        return viewerApiServiceReceivingLegendDataUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceReceivingLegendDataUnexpectedError()))
        );
};

export const updateNotificationMessage = (message: string): Observable<NotificationPaneActionTypes> => {
    const promise = defaultApiFactory().postNotificationUpdate({
        message
    });

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 204:
                        return viewerApiServiceSavedNotificationMessage();
                    case 400:
                        return viewerApiServiceSavingNotificationMessageFailedWithBadRequestException();
                    case 403:
                        return viewerApiServiceSavingNotificationFailedWithUnauthorizedException();
                    case 500:
                    default:
                        return viewerApiServiceSavingNotificationFailedWithUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceSavingNotificationFailedWithUnexpectedError()))
        );
};

export const loadNotificationData = (): Observable<NotificationPaneActionTypes> => {
    const promise = defaultApiFactory().getNotification({});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        let notification: NotificationDTO | null = null;
                        if (response.body.title.length > 0 || response.body.message.length > 0) {
                            notification = response.body;
                        }
                        return viewerApiServiceReceivedNotificationMessage(notification);
                    case 403:
                        return viewerApiServiceReceivingNotificationMessageFailedWithUnauthorizedError();
                    case 404:
                        return viewerApiServiceReceivingNotificationMessageFailedWithNotFoundError();
                    case 500:
                    default:
                        return viewerApiServiceReceivingNotificationMessageFailedWithUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceReceivingNotificationMessageFailedWithUnexpectedError()))
        );
};

export const loadAccessData = (): Observable<AccessDataActionTypes> => {
    const promise = defaultApiFactory().getAccess({});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedAccessData(response.body);
                    case 500:
                    default:
                        return viewerApiServiceReceivingAccessDataFailed();
                }
            }),
            catchError(() => of(viewerApiServiceReceivingAccessDataFailed()))
        );
};

export const loadSearchFeaturesData = (mapSourceId: string): Observable<LoadSearchFeaturesDataActionTypes> => {
    const promise = defaultApiFactory().getSearchFeatures({mapSource: mapSourceId});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceReceivedSearchFeaturesData(mapSourceId, response.body);
                    case 400:
                        return viewerApiServiceReceivingSearchFeaturesDataFailedWithBadRequestError();
                    case 403:
                        return viewerApiServiceReceivingSearchFeaturesDataFailedWithUnauthorizedError();
                    case 404:
                        return viewerApiServiceReceivingSearchFeaturesDataFailedWithNotFoundError();
                    case 500:
                    default:
                        return viewerApiServiceReceivingSearchFeaturesDataFailedWithUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceReceivingSearchFeaturesDataFailedWithUnexpectedError()))
        );
};

export const loadPrefixes = (): Observable<ViewerApiServiceLoadPrefixData> => {
    const promise = defaultApiFactory().getPrefix({});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewApiServiceLoadPrefixDataCompleted(response.body);
                    case 403:
                        return viewerApiServiceLoadPrefixDataFailedWithUnauthorizedError();
                    case 500:
                    default:
                        return viewerApiServiceLoadPrefixDataFailedWithUnexpectedError();
                }
            }),
            catchError(() => of(viewerApiServiceLoadPrefixDataFailedWithUnexpectedError()))
        );
};

export const segmentHeatMap = (featureId: string, featureKey: number): Observable<ViewerApiServiceSegmentHeatMapActionTypes> => {
    const promise = defaultApiFactory().getSegmentHeatMap({id: featureId});

    return from(promise)
        .pipe(
            map((response) => {
                switch (response.status) {
                    case 200:
                        return viewerApiServiceSegmentHeatMapLoadedData(featureId, featureKey, response.body);
                    case 400:
                        return viewerApiServiceSegmentHeatmapLoadingDataFailedWithBadRequestException(featureId);
                    case 403:
                        return viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnauthorizedException(featureId);
                    case 500:
                    default:
                        return viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnexpectedError(featureId);
                }
            }),
            catchError(() => of(viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnexpectedError(featureId)))
        );
};

export const fetchFilterData = (mapLayer: string) => {
    if(mapLayer === 'romoHazardWarnings'){
        return from(viewerApiClientV2().hazardWarningController.getAllTypes())
            .pipe(
                map(types => loadedFilterDataRomoHW(types)),
                catchError(() => of(loadedFilterDataRomoHWFailed()))
            );
    }

    return EMPTY;
};

export class UnauthorizedError extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, UnauthorizedError.prototype);
    }
}

export class UnexpectedError extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, UnexpectedError.prototype);
    }
}
