import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {
    fetchHazardWarningHistory,
    fetchSurfaceDamageHistory,
    loadBridgeHistory,
    loadHistoricalData,
    segmentHeatMap,
    UnauthorizedError
} from '../../../services/ViewerApiService';
import {isActionOf} from 'typesafe-actions';
import {
    catchError,
    filter,
    flatMap,
    ignoreElements,
    map,
    mergeMap,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import {
    mapSceneActiveDialogUpdateSegmentHeatMapData,
    storeNewBridgeHistoricalGraphData,
    storeNewGraphData,
    storeSurfaceDamageHistory,
    storeHazardWarningHistory
} from '../../../scenes/MapScene/actions/reducers/activeDialogs';
import {RootState} from '../../../reducer';
import {EMPTY, interval, of} from 'rxjs';
import {VectorLayerStateTravelTime} from '../../../interfaces/VectorLayerSources/VectorLayerStateTravelTime';
import {viewerApplicationSceneChanged} from '../../../actions';
import {MAP_SCENE} from '../../../scenes';
import {
    closeGraphDialog,
    mapSceneOpenHeatmapTravelTimeFcd,
    openGraphDialog
} from '../../../scenes/MapScene/actions/travelTimeDialog';
import {mapSceneOpenBridgeGraphDialog} from '../../../scenes/MapScene/actions/bridgeDialog';
import {keycloakService} from '@ndw/react-keycloak-authentication';
import {viewerApiServiceReceivedHistoricalData} from '../../../services/ViewerApiService/actions/historicalDataData';
import {viewerApiServiceReceivedBridgeHistory} from '../../../services/ViewerApiService/actions/loadBridgeHistory';
import {PlotData} from 'plotly.js';
import {chartColors} from '../../../styles';
import {ActiveDialogVectorLayerFeatureTravelTimeGraphData} from '../../../interfaces/ActiveDialog';
import {DIALOG_GRAPHS} from '../../../scenes/MapScene/actions';
import {
    viewerApiServiceSegmentHeatMapLoadedData,
    viewerApiServiceSegmentHeatmapLoadingDataFailedWithBadRequestException,
    viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnauthorizedException,
    viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnexpectedError
} from '../../../services/ViewerApiService/actions/segmentHeatMap';
import {SegmentHeatMapDTO} from '../../../generated/ViewerApiClient';
import {parseDateToFormatted} from '../../../services/Utilities';
import {toast} from 'react-toastify';
import {loadSurfaceDamagesHistory} from '../../../scenes/MapScene/actions/romoSurfaceDamagesDialog';
import {loadHazardWarningHistory} from '../../../scenes/MapScene/actions/romoHazardWarningDialog';

const loadBridgeDataOnOpenBridgeGraphDialogEffect: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(mapSceneOpenBridgeGraphDialog)),
        switchMap(({payload: {vild, risIndex}}) => keycloakService.token()
            .pipe(mergeMap(() => loadBridgeHistory(risIndex, vild))))
    );

const loadSurfaceDamagesHistoryEffect: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(loadSurfaceDamagesHistory)),
        map(action => action.payload.id),
        withLatestFrom(keycloakService.token()),
        switchMap(([id]) => fetchSurfaceDamageHistory(id)
            .pipe(map((surfaceDamages) => storeSurfaceDamageHistory(id, surfaceDamages)),
                catchError(error => {
                    if (error instanceof UnauthorizedError) {
                        toast('U heeft momenteel geen rechten om de wegbeschadigingen geschiedenis op te halen.', {
                            type: 'error'
                        });
                    } else {
                        toast('Er is helaas een onbekende fout opgetreden tijdens het ophalen van de geschiedenis van de wegbeschadiging.', {
                            type: 'error'
                        });
                    }

                    return EMPTY;
                })))
    );

const loadHazardWarningHistoryEffect: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(loadHazardWarningHistory)),
        map((action) => action.payload.id),
        withLatestFrom(keycloakService.token()),
        switchMap(([id]) =>
            fetchHazardWarningHistory(id).pipe(
                map((hazardWarnings) => storeHazardWarningHistory(id, hazardWarnings)),
                catchError((error) => {
                    if (error instanceof UnauthorizedError) {
                        toast('U heeft momenteel geen rechten om de geschiedenis van gevaarswaarschuwingen op te halen.', {
                            type: 'error'
                        });
                    } else {
                        toast('Er is helaas een onbekende fout opgetreden tijdens het ophalen van de geschiedenis van de gevaarswaarschuwing.', {
                            type: 'error'
                        });
                    }

                    return EMPTY;
                })
            )
        )
    );

const loadHistoricalDataOnOpenGraphDialogEffect: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(openGraphDialog)),
        switchMap((action) => keycloakService.token()
            .pipe(
                mergeMap(() => loadHistoricalData(
                    action.payload.dialog,
                    action.payload.mapSourceID,
                    action.payload.featureID,
                    action.payload.locationID,
                    action.payload.timestamp
                ))
            ))
    );

const saveBridgeGraphDataOnReceivedBridgeHistoryEffect: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApiServiceReceivedBridgeHistory)),
        map(({payload: {risIndex, vild, bridgeHistoryData}}) =>
            storeNewBridgeHistoricalGraphData(risIndex, vild, bridgeHistoryData))
    );

const saveGraphDataOnReceivedHistoricalDataEffect: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(viewerApiServiceReceivedHistoricalData)),
        map((action) => {
            const {data, dataType, featureID, mapSourceID} = action.payload;

            switch (dataType) {
                case 'travelTime':
                    const mapSource = state$.value.mapScene.data.sources[mapSourceID];
                    if (mapSource.dataLoadMethod !== 'VectorTiles') {
                        return EMPTY;
                    }
                    const travelTimeCurrentData: VectorLayerStateTravelTime | undefined = mapSource.layerMapData![featureID] as VectorLayerStateTravelTime | undefined;

                    if (!travelTimeCurrentData) {
                        return EMPTY;
                    }

                    const dataSetTravelTimeActual: Partial<PlotData> = {
                        x: [],
                        y: [],
                        name: 'Actueel',
                        mode: 'lines+markers',
                        marker: {
                            color: chartColors[0]
                        },
                        line: {
                            color: chartColors[0]
                        }
                    };
                    const dataSetTravelTimeFreeFlow: Partial<PlotData> = {
                        x: [],
                        y: [],
                        name: 'Freeflow',
                        mode: 'lines+markers',
                        marker: {
                            color: chartColors[1]
                        },
                        line: {
                            color: chartColors[1]
                        }
                    };

                    const dataSetVelocityActual: Partial<PlotData> = {
                        x: [],
                        y: [],
                        name: 'Actueel',
                        mode: 'lines+markers',
                        marker: {
                            color: chartColors[0]
                        },
                        line: {
                            color: chartColors[0]
                        }
                    };
                    const dataSetVelocityFreeFlow: Partial<PlotData> = {
                        x: [],
                        y: [],
                        name: 'Freeflow',
                        mode: 'lines+markers',
                        marker: {
                            color: chartColors[1]
                        },
                        line: {
                            color: chartColors[1]
                        }
                    };

                    const dataCastTravelTime: { [key: string]: { travelTime: number, velocity: number } } = data;

                    for(const dataKey in dataCastTravelTime) {
                        if (!dataCastTravelTime.hasOwnProperty(dataKey)) {
                            continue;
                        }

                        const value = dataCastTravelTime[dataKey];

                        (dataSetTravelTimeActual.y as number[]).push(value.travelTime);
                        (dataSetTravelTimeActual.x as string[]).push(dataKey);
                        (dataSetVelocityActual.y as number[]).push(value.velocity);
                        (dataSetVelocityActual.x as string[]).push(dataKey);
                        (dataSetTravelTimeFreeFlow.y as number[]).push(travelTimeCurrentData.freeFlow);
                        (dataSetTravelTimeFreeFlow.x as string[]).push(dataKey);
                        (dataSetVelocityFreeFlow.y as number[]).push(travelTimeCurrentData.freeFlowSpeed);
                        (dataSetVelocityFreeFlow.x as string[]).push(dataKey);
                    }

                    const graphData: ActiveDialogVectorLayerFeatureTravelTimeGraphData = {
                        travelTime: [dataSetTravelTimeActual, dataSetTravelTimeFreeFlow],
                        velocity: [dataSetVelocityActual, dataSetVelocityFreeFlow],
                        timeStamp: Date.now()
                    };

                    return storeNewGraphData(mapSourceID, featureID, graphData);
                case 'flowSpeed':
                    const dataCastFlowSpeed: {
                        [key: string]: { [key: string]: { [key: string]: { flow: number, speed: number } } }
                    } =
                        data;
                    const flowDataSets: { [key: string]: Partial<PlotData> } = {};
                    const speedDataSets: { [key: string]: Partial<PlotData> } = {};
                    let indexCounter: number = 0;

                    for(const dataDate in dataCastFlowSpeed) {
                        if (!dataCastFlowSpeed.hasOwnProperty(dataDate)) {
                            continue;
                        }

                        const dateData = dataCastFlowSpeed[dataDate];

                        for(const laneName in dateData) {
                            if (!dateData.hasOwnProperty(laneName)) {
                                continue;
                            }

                            const laneData = dateData[laneName];

                            for(const laneCategoryName in laneData) {
                                if (!laneData.hasOwnProperty(laneCategoryName)) {
                                    continue;
                                }
                                const lookupName = `${laneName}-${laneCategoryName}`;

                                if (!flowDataSets.hasOwnProperty(lookupName)) {
                                    flowDataSets[lookupName] = {
                                        x: [],
                                        y: [],
                                        name: `${laneCategoryName} - ${laneName}`,
                                        mode: 'lines+markers',
                                        marker: {
                                            color: chartColors[indexCounter]
                                        },
                                        line: {
                                            color: chartColors[indexCounter]
                                        }
                                    };
                                }
                                if (!speedDataSets.hasOwnProperty(lookupName)) {
                                    speedDataSets[lookupName] = {
                                        x: [],
                                        y: [],
                                        name: `${laneCategoryName} - ${laneName}`,
                                        mode: 'lines+markers',
                                        marker: {
                                            color: chartColors[indexCounter]
                                        },
                                        line: {
                                            color: chartColors[indexCounter]
                                        }
                                    };
                                }
                                indexCounter++;

                                (flowDataSets[lookupName].x as string[]).push(dataDate);
                                (flowDataSets[lookupName].y as number[]).push(laneData[laneCategoryName].flow);
                                (speedDataSets[lookupName].x as string[]).push(dataDate);
                                (speedDataSets[lookupName].y as number[]).push(laneData[laneCategoryName].speed);
                            }
                        }
                    }

                    return storeNewGraphData('flowSpeed', featureID, {
                        speed: Object.values(speedDataSets),
                        flow: Object.values(flowDataSets),
                        timeStamp: Date.now()
                    });
            }
        })
    );

const startReloadingOnOpenDialogEffect: Epic = (action$) => {
    let openGraphs: {
        [key: string]: {
            mapSourceID: string;
            featureID: number | string;
            locationID: string;
            dialog: DIALOG_GRAPHS;
            timestamp: number;
        }
    } = {};

    action$
        .pipe(
            filter(isActionOf(openGraphDialog))
        )
        .subscribe((action) => {
            openGraphs[`${action.payload.mapSourceID}-${action.payload.featureID}`] = action.payload;
        });

    action$
        .pipe(filter(isActionOf(closeGraphDialog)))
        .subscribe((action) => {
            delete openGraphs[`${action.payload.mapSourceID}-${action.payload.featureID}`];
        });

    action$
        .pipe(
            filter(isActionOf(viewerApplicationSceneChanged)),
            filter((action) => action.payload.scene !== MAP_SCENE)
        )
        .subscribe(() => {
            openGraphs = {};
        });

    return action$.pipe(
        filter(isActionOf(viewerApplicationSceneChanged)),
        filter((action) => action.payload.scene === MAP_SCENE),
        switchMap(() => interval(60000)
            .pipe(
                takeUntil(action$.pipe(
                    filter(isActionOf(viewerApplicationSceneChanged)),
                    filter((action) => action.payload.scene !== MAP_SCENE)
                )),
                filter(() => Object.keys(openGraphs).length > 0),
                flatMap(() => {
                    return of(...Object.values(openGraphs))
                        .pipe(
                            switchMap((action) => keycloakService.token()
                                .pipe(
                                    mergeMap(() => loadHistoricalData(
                                        action.dialog,
                                        action.mapSourceID,
                                        action.featureID,
                                        action.locationID,
                                        action.timestamp
                                    ))
                                ))
                        );
                })
            ))
    );
};

const loadSegmentHeatmapDataOnOpenHeatmapInTravelTimeFcdGraphEffect: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneOpenHeatmapTravelTimeFcd)),
        switchMap(({payload: {featureId, featureKey}}) => keycloakService.token()
            .pipe(
                mergeMap(() => segmentHeatMap(featureId, featureKey))
            )
        )
    );

const showErrorMessageOnSegmentHeatMapDataLoadingFailedEffect: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            viewerApiServiceSegmentHeatmapLoadingDataFailedWithBadRequestException,
            viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnauthorizedException,
            viewerApiServiceSegmentHeatmapLoadingDataFailedWithUnexpectedError
        ])),
        tap(({payload: {featureId}}) => {
            toast('Kan de tijdwegdiagram voor popup met FCD Traject ' + featureId + ' niet inladen', {
                type: 'error'
            });
        }),
        ignoreElements()
    );

const updateSegmentHeatMapDataOnReceivedSegmentHeatMapDataEffect: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceSegmentHeatMapLoadedData)),
        map((
            {
                payload: {
                    featureId,
                    featureKey,
                    segmentData
                }
            }
        ): { featureId: string, featureKey: number, segmentData: SegmentHeatMapDTO } => {
            const xAxis: string[] = [];
            const secondsOffset = -(new Date()).getTimezoneOffset() * 60 * 1000;

            for(const xValue of segmentData.x) {
                const dateObject = new Date(xValue + secondsOffset);
                xAxis.push(parseDateToFormatted(dateObject, false));
            }

            return {
                featureId: featureId,
                featureKey: featureKey,
                segmentData: {
                    ...segmentData,
                    x: xAxis as unknown as number[],
                    rangeX: [
                        xAxis[0] as unknown as number,
                        xAxis[xAxis.length - 1] as unknown as number
                    ]
                }
            };
        }),
        map(({
            featureId,
            featureKey,
            segmentData
        }) => mapSceneActiveDialogUpdateSegmentHeatMapData(featureId, featureKey, segmentData))
    );

const graphEpicEffects: Epic = combineEpics(
    loadBridgeDataOnOpenBridgeGraphDialogEffect,
    loadSurfaceDamagesHistoryEffect,
    loadHazardWarningHistoryEffect,
    loadHistoricalDataOnOpenGraphDialogEffect,
    saveBridgeGraphDataOnReceivedBridgeHistoryEffect,
    saveGraphDataOnReceivedHistoricalDataEffect,
    startReloadingOnOpenDialogEffect,
    loadSegmentHeatmapDataOnOpenHeatmapInTravelTimeFcdGraphEffect,
    updateSegmentHeatMapDataOnReceivedSegmentHeatMapDataEffect,
    showErrorMessageOnSegmentHeatMapDataLoadingFailedEffect
);

export default graphEpicEffects;
