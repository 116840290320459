import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {debounceTime, filter, flatMap, ignoreElements, map, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import UrlService from '../../services/UrlService';
import {urlServiceUpdateSettingsInUrl} from '../../services/UrlService/actions';
import {RootState} from '../../reducer';
import {EMPTY, of} from 'rxjs';
import {disableMapLayer, enableMapLayer, updateMapIsReadyStatus, updateMapViewport} from '../../scenes/MapScene/actions/mapBoxComponent';
import {
    changeCurrentTheme,
    changeCurrentTrafficCenter,
    userSettingsReducerShowRecordIds,
    userSettingsReducerShowTrafficJamIcons
} from '../../modules/UserSettingsReducer/actions';
import {
    mapSceneOverviewTableChangeOverviewTableSource,
    setNewVisibleOverviewAllTable
} from '../../scenes/MapScene/actions/overviewTableComponent';
import {
    setNewDfineFilterStatusOnlyRvmNetwork,
    setNewFdVerifiedTrafficJamsFilterStatus,
    setNewPrefixFilterForLayer,
    setNewRomoJerkFilter,
    setNewRomoJerkDeltaFilter,
    setNewRomoSizeFilter,
    setNewRomoSizeDeltaFilter,
    setNewRomoTimeFilter,
    setNewRomoRoadOperatorFilter,
    setNewWazeAlertFilterStatusSet,
    setNewWazeAlertNdwKnownFilterStatus,
    setRomoHWSelectedTypes,
    setNewRomoHWDaysInHistoryFilter
} from '../../scenes/MapScene/actions/reducers/filterAndSearch';
import {mapSceneMapboxLayersUpdateMapLayerConfiguration} from '../../scenes/MapScene/actions/reducers/mapboxLayers';
import {mapSceneGeneralToggleMapMovementAvailable} from '../../scenes/MapScene/actions';
import {
    mapSceneTrafficInformationSortingDirection,
    mapSceneTrafficInformationSortingValue
} from '../../scenes/MapScene/actions/reducers/trafficInformation';

const enableMapLayersOnMapLoadingIsFinished: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf([
            updateMapIsReadyStatus,
            mapSceneMapboxLayersUpdateMapLayerConfiguration
        ])),
        filter(() => state$.value.mapScene.general.mapIsReady),
        filter(() => Object.keys(state$.value.mapScene.data.sources).length > 0),
        flatMap(() => {
            const urlParameterValue = UrlService.getInstance().getStringValueFromUrl('activeLayers', '');
            if (urlParameterValue.length === 0) {
                return EMPTY;
            }

            return of(...decodeURIComponent(urlParameterValue).split(',')).pipe(map((value) => {
                return enableMapLayer(value);
            }));
        })
    );

const updateSettingsInUrlOnChaneInformation: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf([
            changeCurrentTheme,
            changeCurrentTrafficCenter,
            disableMapLayer,
            enableMapLayer,
            setNewVisibleOverviewAllTable,
            mapSceneOverviewTableChangeOverviewTableSource,
            setNewWazeAlertNdwKnownFilterStatus,
            setNewWazeAlertFilterStatusSet,
            updateMapViewport,
            userSettingsReducerShowRecordIds,
            userSettingsReducerShowTrafficJamIcons,
            setNewDfineFilterStatusOnlyRvmNetwork,
            setNewPrefixFilterForLayer,
            mapSceneGeneralToggleMapMovementAvailable,
            mapSceneTrafficInformationSortingValue,
            mapSceneTrafficInformationSortingDirection,
            setNewFdVerifiedTrafficJamsFilterStatus,
            setNewRomoSizeFilter,
            setNewRomoSizeDeltaFilter,
            setNewRomoJerkFilter,
            setNewRomoJerkDeltaFilter,
            setNewRomoTimeFilter,
            setNewRomoRoadOperatorFilter,
            setRomoHWSelectedTypes,
            setNewRomoHWDaysInHistoryFilter
        ])),
        map(() => urlServiceUpdateSettingsInUrl())
    );

const saveChangesToUrlOnDebouncedUpdateSettingsInUrl: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(urlServiceUpdateSettingsInUrl)),
        filter(() => state$.value.mapScene.general.mapIsReady),
        debounceTime(1000),
        tap(() => UrlService.getInstance().saveChangeToUrl(state$.value)),
        ignoreElements()
    );

const configurationEpics: Epic = combineEpics(
    enableMapLayersOnMapLoadingIsFinished,
    updateSettingsInUrlOnChaneInformation,
    saveChangesToUrlOnDebouncedUpdateSettingsInUrl
);

export default configurationEpics;
