import React, {ReactElement} from 'react';
import {Props} from './Props';
import StyledBoolean from '../../../../../../components/StyledBoolean';
import {LineChart} from 'react-chartkick';
import {chartColors} from '../../../../../../styles';

class DialogBridge extends React.Component<Props> {

    componentDidMount(): void {
        this.loadBridgeGraphData();
    }

    loadBridgeGraphData = (): void => {
        const props: Readonly<Props> = this.props;
        const {properties} = props;
        props.onOpenDialog(properties.risIndex, properties.vild);
    };

    render(): ReactElement {
        const props: Readonly<Props> = this.props;
        const {bridgeHistoryData, properties, showRecordIds} = props;

        return <React.Fragment>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Naam</th>
                        <td>{properties.name}</td>
                    </tr>
                    <tr>
                        <th>RIS Index</th>
                        <td>{properties.risIndex}</td>
                    </tr>
                    <tr>
                        <th>Vild nr.</th>
                        <td>{properties.vild}</td>
                    </tr>
                    <tr>
                        <th>Open</th>
                        <td>
                            <StyledBoolean
                                value={properties.open}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <br/>

            {bridgeHistoryData && <LineChart
                colors={[chartColors[0]]}
                data={bridgeHistoryData}
                download={`Brugopening geschiedenis ${this.getBridgeName()}.png`}
            />}
        </React.Fragment>;
    }

    private getBridgeName = (): string => {
        const props: Readonly<Props> = this.props;
        const {properties} = props;

        if (properties.name && properties.name.length) {
            return properties.name;
        }
        if (properties.risIndex && properties.risIndex.length) {
            return properties.risIndex;
        }
        if (properties.vild && properties.vild.length) {
            return properties.vild;
        }

        return properties.id;
    }
}

export default DialogBridge;
