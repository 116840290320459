import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {css} from 'aphrodite/no-important';
import {Props} from './Props';
import BearingCompass from '../../../../../../components/BearingCompass';
import {format} from 'date-fns';
import MeterBar from '../../../../../../components/MeterBar';
import {TYPE_NAMES} from '../../../../../../services/RomoHWService';
import {roadOperatorTypeLabels} from '../../../../../../constants';
import {loadHazardWarningHistory} from '../../../../actions/romoHazardWarningDialog';
import HazardWarningHistory from './HazardWarningHistory';
import {HazardWarningType} from '../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesRomoHazardWarnings';
import styles from './styles';

const MAX_INTENSITY = 6;
const TYPES_WITH_HISTORY = [HazardWarningType.POTHOLE, HazardWarningType.BUMP];

export const DialogRomoHazardWarning: React.FunctionComponent<Props> = ({
    properties: {bearing, id, type: hazardType, dateTime, intensity, roadOperatorName, roadOperatorType},
    showRecordIds,
    history
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (TYPES_WITH_HISTORY.includes(hazardType) && history == null) {
            dispatch(loadHazardWarningHistory(id));
        }
    }, [dispatch, history, id, hazardType]);

    const formattedWarningTimestamp = format(new Date(dateTime), 'dd-MM-yyyy');
    const intensityPercentage = intensity != undefined && Math.ceil((intensity / MAX_INTENSITY) * 100);

    return (
        <>
            <table>
                <tbody>
                    {showRecordIds && (
                        <tr>
                            <th>ID</th>
                            <td>{id}</td>
                        </tr>
                    )}
                    <tr>
                        <th>Type</th>
                        <td>{TYPE_NAMES[hazardType]}</td>
                    </tr>
                    <tr>
                        <th>Richting</th>
                        <td>{bearing != undefined ? <BearingCompass bearing={bearing}></BearingCompass> : '-'}</td>
                    </tr>
                    {intensityPercentage && (
                        <tr>
                            <th>Zwaarte</th>
                            <td>
                                <MeterBar
                                    percentage={intensityPercentage}
                                    title={intensity != undefined ? `${intensity} / ${MAX_INTENSITY}` : undefined}
                                    backgroundColor="#ccc"
                                    fillColor="#f8b74c"
                                />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th>Laatst gewijzigd op</th>
                        <td>{formattedWarningTimestamp}</td>
                    </tr>
                    {roadOperatorName && (
                        <tr>
                            <th>Wegbeheerder</th>
                            <td>
                                {roadOperatorName} {roadOperatorType && <span>({roadOperatorTypeLabels[roadOperatorType]})</span>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {history && (
                <section className={css(styles.historySection)}>
                    <HazardWarningHistory history={history} minIntensity={0} maxIntensity={MAX_INTENSITY} />
                </section>
            )}
        </>
    );
};
