import {combineEpics, Epic} from 'redux-observable';
import {isActionOf} from 'typesafe-actions';
import {filter, mergeMap, switchMap} from 'rxjs/operators';
import {activatedTrafficMessagesPane} from '../../../scenes/MapScene/actions/trafficMessagesPane';
import {keycloakService} from '@ndw/react-keycloak-authentication';
import {enableMapLayer} from '../../../scenes/MapScene/actions/mapBoxComponent';
import {trafficInformationResources} from '../../../constants';

const loadTrafficMessagesOnActivatedTrafficMessagesPane: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(activatedTrafficMessagesPane)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => trafficInformationResources.map(source => enableMapLayer(source.mapLayer)))
            ))
    );

const trafficInformationPaneEpics: Epic = combineEpics(
    loadTrafficMessagesOnActivatedTrafficMessagesPane,
);

export default trafficInformationPaneEpics;
