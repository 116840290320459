/* eslint-disable */
// tslint:disable

import * as request from "superagent";
import {
    SuperAgentStatic,
    SuperAgentRequest,
    Response
} from "superagent";

export type RequestHeaders = {
    [header: string]: string;
}
export type RequestHeadersHandler = (headers: RequestHeaders) => RequestHeaders;

export type ConfigureAgentHandler = (agent: SuperAgentStatic) => SuperAgentStatic;

export type ConfigureRequestHandler = (agent: SuperAgentRequest) => SuperAgentRequest;

export type CallbackHandler = (err: any, res ? : request.Response) => void;

export type BridgeHistoryDTO = {};

export type CoordinatesDTO = {
    'latitude': number;
    'longitude': number;
};

export type EdoDTO = {
    'id': string;
    'dataType': string;
    'icon': string;
    'country': string;
    'road': string;
    'hmp': string;
    'hmpSuffix': string;
    'laneIndication': string;
    'bearing': number;
    'probability': number;
    'createdAt': string;
    'locationType': string;
    'latitude': number;
    'longitude': number;
    'status': string;
    'location': string;
    'note': string;
    'newData': boolean;
    'trafficCenter': string;
    'hectometrePost': string;
    'hectometrePostRoad': string;
};

export type EdoStatusDTO = {
    'id': string;
    'status': "NONE" | "START_ACTION" | "SELF_ACTION" | "KNOW_MESSAGE" | "NO_ACTION";
    'location': string;
    'note': string;
    'open': boolean;
    'openExpirationMillis': number;
};

export type HistoricalDTO = {};

export type HistoricalSummedDTO = {};

export type HistoricalSummedDataDTO = {
    'travelTime': number;
    'velocity': number;
};

export type LayerConfigurationDTO = {
    'mapSources': Array < MapSourceDTO >
    ;
    'mapLayers': Array < MapLayerDTO >
    ;
};

export type LayerDTO = {
    'id': "bridge" | "camera" | "drips" | "fcdClass" | "flowSpeed" | "here" | "mrm" | "msi" | "parking" | "roadWork" | "situation" | "travelTimeFcd" | "travelTimeOther" | "travelTimeDrips" | "waze";
    'type': "base" | "data" | "details" | "update";
    'data': {};
};

export type LegendColorDTO = {
    'color': string;
    'colorBlind': string;
    'name': string;
};

export type LegendDTO = {
    'icons': Array < LegendIconDTO >
    ;
    'colors': Array < LegendColorDTO >
    ;
};

export type LegendIconDTO = {
    'icon': string;
    'name': string;
};

export type MapLayerDTO = {
    'id': string;
    'name': string;
    'category': string;
    'description': string;
    'explanation' ? : string;
    'source': string;
    'layers': string;
    'zIndex': number;
};

export type MapSourceDTO = {
    'id': string;
    'name': string;
    'sourceUrl': string;
    'sourceUpdateUrl': string;
    'loadBaseData': boolean;
    'refreshUpdate': boolean;
    'dataLoadMethod': "VectorTiles" | "GeoJSON";
    'updateInterval': number;
    'sourceLayer': string;
    'sourceConfiguration': {};
    'searchProperty': string;
    'websocketReload': boolean;
    'websocketUrl': string;
    'colorBlindSupported': boolean;
};

export type NotificationDTO = {
    'id': string;
    'title': string;
    'message': string;
    'timestamp': string;
};

export type PrefixDTO = {
    'prefix': string;
    'type': string;
};

export type ReleaseNoteDTO = {
    'version': string;
    'title': string;
    'changes': Array < string >
    ;
};

export type SearchFeatureDTO = {
    'coordinates': CoordinatesDTO;
    'displayValue': string;
    'id': number;
    'searchValue': string;
};

export type SegmentHeatMapDTO = {
    'x': Array < number >
    ;
    'y': Array < number >
    ;
    'z': Array < Array < number >
        >
    ;
    'rangeX': Array < number >
    ;
    'rangeY': Array < number >
    ;
};

export type SurfaceDamageDTO = {
    'id': number;
    'heading': number;
    'clusterJerk': number;
    'clusterSize': number;
    'recentEvent': string;
};

export type TrafficCenterDTO = {
    'key': string;
    'name': string;
};

export type TrafficMessageDTO = {
    'id': string;
    'version': number;
    'latitude': number;
    'longitude': number;
    'dateTime': string;
    'description': string;
    'eventType': string;
};

export type WazeDTO = {
    'id': string;
    'dataType': string;
    'icon': string;
    'ndw': boolean;
    'ndwId': string;
    'type': string;
    'subType': string;
    'city': string;
    'street': string;
    'confidence': number;
    'reliability': number;
    'severity': number;
    'thumbsUp': number;
    'priority': number;
    'reporterRating': number;
    'publicationTime': string;
    'lastConfirmedTime': string;
    'locationType': string;
    'latitude': number;
    'longitude': number;
    'status': string;
    'location': string;
    'note': string;
    'newData': boolean;
    'trafficCenter': string;
    'hectometrePost': string;
    'hectometrePostRoad': string;
};

export type WazeStatusDTO = {
    'id': string;
    'status': "NONE" | "START_ACTION" | "SELF_ACTION" | "KNOW_MESSAGE" | "NO_ACTION";
    'type': string;
    'subType': string;
    'location': string;
    'note': string;
    'open': boolean;
    'openExpirationMillis': number;
};

export type AccessDTO = {
    'development': boolean;
    'permissions': Array < string >
    ;
};

export type CreatedDTO = {
    'id': string;
};

export type ErrorDTO = {
    'message': string;
};

export type Response_getPrefix_200 = Array < PrefixDTO >
;

export type Response_getReleaseNotes_200 = Array < ReleaseNoteDTO >
;

export type Response_getSearchFeatures_200 = Array < SearchFeatureDTO >
;

export type Response_getTrafficCenter_200 = Array < TrafficCenterDTO >
;

export type Response_getWazeExport_200 = {};

export type Response_getRomoHistory_200 = Array < SurfaceDamageDTO >
;

export type Logger = {
    log: (line: string) => any
};

export interface ResponseWithBody < S extends number, T > extends Response {
    status: S;
    body: T;
}

export type QueryParameters = {
    [param: string]: any
};

export interface CommonRequestOptions {
    $queryParameters ? : QueryParameters;
    $domain ? : string;
    $path ? : string | ((path: string) => string);
    $retries ? : number; // number of retries; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#retrying-requests
    $timeout ? : number; // request timeout in milliseconds; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#timeouts
    $deadline ? : number; // request deadline in milliseconds; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#timeouts
}

/**
 * 
 * @class ViewerApiClient
 * @param {(string)} [domainOrOptions] - The project domain.
 */
export class ViewerApiClient {

    private domain: string = "http://localhost:8070/api/v1";
    private errorHandlers: CallbackHandler[] = [];
    private requestHeadersHandler ? : RequestHeadersHandler;
    private configureAgentHandler ? : ConfigureAgentHandler;
    private configureRequestHandler ? : ConfigureRequestHandler;

    constructor(domain ? : string, private logger ? : Logger) {
        if (domain) {
            this.domain = domain;
        }
    }

    getDomain() {
        return this.domain;
    }

    addErrorHandler(handler: CallbackHandler) {
        this.errorHandlers.push(handler);
    }

    setRequestHeadersHandler(handler: RequestHeadersHandler) {
        this.requestHeadersHandler = handler;
    }

    setConfigureAgentHandler(handler: ConfigureAgentHandler) {
        this.configureAgentHandler = handler;
    }

    setConfigureRequestHandler(handler: ConfigureRequestHandler) {
        this.configureRequestHandler = handler;
    }

    private request(method: string, url: string, body: any, headers: RequestHeaders, queryParameters: QueryParameters, form: any, reject: CallbackHandler, resolve: CallbackHandler, opts: CommonRequestOptions) {
        if (this.logger) {
            this.logger.log(`Call ${method} ${url}`);
        }

        const agent = this.configureAgentHandler ?
            this.configureAgentHandler(request.default) :
            request.default;

        let req = agent(method, url);
        if (this.configureRequestHandler) {
            req = this.configureRequestHandler(req);
        }

        req = req.query(queryParameters);

        if (this.requestHeadersHandler) {
            headers = this.requestHeadersHandler({
                ...headers
            });
        }

        req.set(headers);

        if (body) {
            req.send(body);

            if (typeof(body) === 'object' && !(body.constructor.name === 'Buffer')) {
                headers['content-type'] = 'application/json';
            }
        }

        if (Object.keys(form).length > 0) {
            req.type('form');
            req.send(form);
        }

        if (opts.$retries && opts.$retries > 0) {
            req.retry(opts.$retries);
        }

        if (opts.$timeout && opts.$timeout > 0 || opts.$deadline && opts.$deadline > 0) {
            req.timeout({
                deadline: opts.$deadline,
                response: opts.$timeout
            });
        }

        req.end((error, response) => {
            // an error will also be emitted for a 4xx and 5xx status code
            // the error object will then have error.status and error.response fields
            // see superagent error handling: https://github.com/visionmedia/superagent/blob/master/docs/index.md#error-handling
            if (error) {
                reject(error);
                this.errorHandlers.forEach(handler => handler(error));
            } else {
                resolve(response);
            }
        });
    }

    private convertParameterCollectionFormat < T > (param: T, collectionFormat: string | undefined): T | string {
        if (Array.isArray(param) && param.length >= 2) {
            switch (collectionFormat) {
                case "csv":
                    return param.join(",");
                case "ssv":
                    return param.join(" ");
                case "tsv":
                    return param.join("\t");
                case "pipes":
                    return param.join("|");
                default:
                    return param;
            }
        }

        return param;
    }

    getAccessURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/access';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getAccess
     */
    getAccess(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, AccessDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/access';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getBridgeHistoryURL(parameters: {
        'risIndex': string,
        'vild': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/bridgeHistory';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['risIndex'] !== undefined) {
            queryParameters['risIndex'] = this.convertParameterCollectionFormat(
                parameters['risIndex'],
                ''
            );
        }

        if (parameters['vild'] !== undefined) {
            queryParameters['vild'] = this.convertParameterCollectionFormat(
                parameters['vild'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getBridgeHistory
     * @param {string} risIndex - 
     * @param {string} vild - 
     */
    getBridgeHistory(parameters: {
        'risIndex': string,
        'vild': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, BridgeHistoryDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/bridgeHistory';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['risIndex'] !== undefined) {
                queryParameters['risIndex'] = this.convertParameterCollectionFormat(
                    parameters['risIndex'],
                    ''
                );
            }

            if (parameters['risIndex'] === undefined) {
                reject(new Error('Missing required  parameter: risIndex'));
                return;
            }

            if (parameters['vild'] !== undefined) {
                queryParameters['vild'] = this.convertParameterCollectionFormat(
                    parameters['vild'],
                    ''
                );
            }

            if (parameters['vild'] === undefined) {
                reject(new Error('Missing required  parameter: vild'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getHealthURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/health';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getHealth
     */
    getHealth(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/health';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getHistoricalURL(parameters: {
        'dataType': "flowSpeed" | "travelTime",
        'id': string,
        'timestamp' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/historical';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['dataType'] !== undefined) {
            queryParameters['dataType'] = this.convertParameterCollectionFormat(
                parameters['dataType'],
                ''
            );
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters['timestamp'] !== undefined) {
            queryParameters['timestamp'] = this.convertParameterCollectionFormat(
                parameters['timestamp'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getHistorical
     * @param {string} dataType - 
     * @param {string} id - 
     * @param {string} timestamp - 
     */
    getHistorical(parameters: {
        'dataType': "flowSpeed" | "travelTime",
        'id': string,
        'timestamp' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, HistoricalDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/historical';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['dataType'] !== undefined) {
                queryParameters['dataType'] = this.convertParameterCollectionFormat(
                    parameters['dataType'],
                    ''
                );
            }

            if (parameters['dataType'] === undefined) {
                reject(new Error('Missing required  parameter: dataType'));
                return;
            }

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['timestamp'] !== undefined) {
                queryParameters['timestamp'] = this.convertParameterCollectionFormat(
                    parameters['timestamp'],
                    ''
                );
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getHistoricalSummedURL(parameters: {
        'dataType': "flowSpeed" | "travelTime",
        'ids': Array < string >
            ,
        'timestamp' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/historicalSummed';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['dataType'] !== undefined) {
            queryParameters['dataType'] = this.convertParameterCollectionFormat(
                parameters['dataType'],
                ''
            );
        }

        if (parameters['ids'] !== undefined) {
            queryParameters['ids'] = this.convertParameterCollectionFormat(
                parameters['ids'],
                'multi'
            );
        }

        if (parameters['timestamp'] !== undefined) {
            queryParameters['timestamp'] = this.convertParameterCollectionFormat(
                parameters['timestamp'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getHistoricalSummed
     * @param {string} dataType - 
     * @param {array} ids - 
     * @param {string} timestamp - 
     */
    getHistoricalSummed(parameters: {
        'dataType': "flowSpeed" | "travelTime",
        'ids': Array < string >
            ,
        'timestamp' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, HistoricalSummedDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/historicalSummed';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['dataType'] !== undefined) {
                queryParameters['dataType'] = this.convertParameterCollectionFormat(
                    parameters['dataType'],
                    ''
                );
            }

            if (parameters['dataType'] === undefined) {
                reject(new Error('Missing required  parameter: dataType'));
                return;
            }

            if (parameters['ids'] !== undefined) {
                queryParameters['ids'] = this.convertParameterCollectionFormat(
                    parameters['ids'],
                    'multi'
                );
            }

            if (parameters['ids'] === undefined) {
                reject(new Error('Missing required  parameter: ids'));
                return;
            }

            if (parameters['timestamp'] !== undefined) {
                queryParameters['timestamp'] = this.convertParameterCollectionFormat(
                    parameters['timestamp'],
                    ''
                );
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getLayerURL(parameters: {
        'id': "bridge" | "camera" | "drips" | "fcdClass" | "flowSpeed" | "here" | "mrm" | "msi" | "parking" | "roadWork" | "situation" | "travelTimeFcd" | "travelTimeOther" | "travelTimeDrips" | "waze",
        'layerType': "base" | "data" | "details" | "update",
        'colorBlind': boolean,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/layer';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters['layerType'] !== undefined) {
            queryParameters['layerType'] = this.convertParameterCollectionFormat(
                parameters['layerType'],
                ''
            );
        }

        if (parameters['colorBlind'] !== undefined) {
            queryParameters['colorBlind'] = this.convertParameterCollectionFormat(
                parameters['colorBlind'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getLayer
     * @param {string} id - 
     * @param {string} layerType - 
     * @param {boolean} colorBlind - 
     */
    getLayer(parameters: {
        'id': "bridge" | "camera" | "drips" | "fcdClass" | "flowSpeed" | "here" | "mrm" | "msi" | "parking" | "roadWork" | "situation" | "travelTimeFcd" | "travelTimeOther" | "travelTimeDrips" | "waze",
        'layerType': "base" | "data" | "details" | "update",
        'colorBlind': boolean,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, LayerDTO > | ResponseWithBody < 304, ErrorDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/layer';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['layerType'] !== undefined) {
                queryParameters['layerType'] = this.convertParameterCollectionFormat(
                    parameters['layerType'],
                    ''
                );
            }

            if (parameters['layerType'] === undefined) {
                reject(new Error('Missing required  parameter: layerType'));
                return;
            }

            if (parameters['colorBlind'] !== undefined) {
                queryParameters['colorBlind'] = this.convertParameterCollectionFormat(
                    parameters['colorBlind'],
                    ''
                );
            }

            if (parameters['colorBlind'] === undefined) {
                reject(new Error('Missing required  parameter: colorBlind'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getLayerConfigurationURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/layerConfiguration';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getLayerConfiguration
     */
    getLayerConfiguration(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, LayerConfigurationDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/layerConfiguration';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getLegendURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/legend';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getLegend
     */
    getLegend(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, LegendDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/legend';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getNotificationURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/notification';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getNotification
     */
    getNotification(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, NotificationDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/notification';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postNotificationUpdateURL(parameters: {
        'title' ? : string,
        'message' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/notificationUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#postNotificationUpdate
     * @param {string} title - 
     * @param {string} message - 
     */
    postNotificationUpdate(parameters: {
        'title' ? : string,
        'message' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/notificationUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['title'] !== undefined) {
                form['title'] = parameters['title'];
            }

            if (parameters['message'] !== undefined) {
                form['message'] = parameters['message'];
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getPrefixURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/prefix';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getPrefix
     */
    getPrefix(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getPrefix_200 > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/prefix';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getReleaseNotesURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/releaseNotes';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getReleaseNotes
     */
    getReleaseNotes(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getReleaseNotes_200 > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/releaseNotes';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getSearchFeaturesURL(parameters: {
        'mapSource': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/searchFeatures';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['mapSource'] !== undefined) {
            queryParameters['mapSource'] = this.convertParameterCollectionFormat(
                parameters['mapSource'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getSearchFeatures
     * @param {string} mapSource - 
     */
    getSearchFeatures(parameters: {
        'mapSource': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getSearchFeatures_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/searchFeatures';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['mapSource'] !== undefined) {
                queryParameters['mapSource'] = this.convertParameterCollectionFormat(
                    parameters['mapSource'],
                    ''
                );
            }

            if (parameters['mapSource'] === undefined) {
                reject(new Error('Missing required  parameter: mapSource'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getSegmentHeatMapURL(parameters: {
        'id': string,
        'timestamp' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/segmentHeatMap';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters['timestamp'] !== undefined) {
            queryParameters['timestamp'] = this.convertParameterCollectionFormat(
                parameters['timestamp'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getSegmentHeatMap
     * @param {string} id - 
     * @param {string} timestamp - 
     */
    getSegmentHeatMap(parameters: {
        'id': string,
        'timestamp' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, SegmentHeatMapDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/segmentHeatMap';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['timestamp'] !== undefined) {
                queryParameters['timestamp'] = this.convertParameterCollectionFormat(
                    parameters['timestamp'],
                    ''
                );
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getTrafficCenterURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/trafficCenter';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getTrafficCenter
     */
    getTrafficCenter(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getTrafficCenter_200 > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/trafficCenter';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postUploadFilesFileURL(parameters: {
        'applicationName': string,
        'applicationSecret': string,
        'file': {},
        'name': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/uploadFilesFile';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['applicationName'] !== undefined) {
            queryParameters['applicationName'] = this.convertParameterCollectionFormat(
                parameters['applicationName'],
                ''
            );
        }

        if (parameters['applicationSecret'] !== undefined) {
            queryParameters['applicationSecret'] = this.convertParameterCollectionFormat(
                parameters['applicationSecret'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#postUploadFilesFile
     * @param {string} applicationName - 
     * @param {string} applicationSecret - 
     * @param {file} file - 
     * @param {string} name - 
     */
    postUploadFilesFile(parameters: {
        'applicationName': string,
        'applicationSecret': string,
        'file': {},
        'name': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/uploadFilesFile';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'multipart/form-data';

            if (parameters['applicationName'] !== undefined) {
                queryParameters['applicationName'] = this.convertParameterCollectionFormat(
                    parameters['applicationName'],
                    ''
                );
            }

            if (parameters['applicationName'] === undefined) {
                reject(new Error('Missing required  parameter: applicationName'));
                return;
            }

            if (parameters['applicationSecret'] !== undefined) {
                queryParameters['applicationSecret'] = this.convertParameterCollectionFormat(
                    parameters['applicationSecret'],
                    ''
                );
            }

            if (parameters['applicationSecret'] === undefined) {
                reject(new Error('Missing required  parameter: applicationSecret'));
                return;
            }

            if (parameters['file'] !== undefined) {
                form['file'] = parameters['file'];
            }

            if (parameters['file'] === undefined) {
                reject(new Error('Missing required  parameter: file'));
                return;
            }

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters['name'] === undefined) {
                reject(new Error('Missing required  parameter: name'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getWazeExportURL(parameters: {
        'authorization': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeExport';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['authorization'] !== undefined) {
            queryParameters['authorization'] = this.convertParameterCollectionFormat(
                parameters['authorization'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getWazeExport
     * @param {string} authorization - 
     */
    getWazeExport(parameters: {
        'authorization': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getWazeExport_200 > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeExport';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['authorization'] !== undefined) {
                queryParameters['authorization'] = this.convertParameterCollectionFormat(
                    parameters['authorization'],
                    ''
                );
            }

            if (parameters['authorization'] === undefined) {
                reject(new Error('Missing required  parameter: authorization'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getWazeStatusURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeStatus';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getWazeStatus
     * @param {string} id - 
     */
    getWazeStatus(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, WazeStatusDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeStatus';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postWazeUpdateURL(parameters: {
        'id': string,
        'status' ? : "START_ACTION" | "SELF_ACTION" | "KNOW_MESSAGE" | "NO_ACTION",
        'type' ? : "ACCIDENT" | "WEATHERHAZARD",
        'subType' ? : "ACCIDENT_MAJOR" | "ACCIDENT_MINOR" | "HAZARD_ON_ROAD" | "HAZARD_ON_ROAD_CAR_STOPPED" | "HAZARD_ON_ROAD_OBJECT" | "HAZARD_ON_SHOULDER_CAR_STOPPED",
        'location' ? : string,
        'note' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#postWazeUpdate
     * @param {string} id - 
     * @param {string} status - 
     * @param {string} type - 
     * @param {string} subType - 
     * @param {string} location - 
     * @param {string} note - 
     */
    postWazeUpdate(parameters: {
        'id': string,
        'status' ? : "START_ACTION" | "SELF_ACTION" | "KNOW_MESSAGE" | "NO_ACTION",
        'type' ? : "ACCIDENT" | "WEATHERHAZARD",
        'subType' ? : "ACCIDENT_MAJOR" | "ACCIDENT_MINOR" | "HAZARD_ON_ROAD" | "HAZARD_ON_ROAD_CAR_STOPPED" | "HAZARD_ON_ROAD_OBJECT" | "HAZARD_ON_SHOULDER_CAR_STOPPED",
        'location' ? : string,
        'note' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['status'] !== undefined) {
                form['status'] = parameters['status'];
            }

            if (parameters['type'] !== undefined) {
                form['type'] = parameters['type'];
            }

            if (parameters['subType'] !== undefined) {
                form['subType'] = parameters['subType'];
            }

            if (parameters['location'] !== undefined) {
                form['location'] = parameters['location'];
            }

            if (parameters['note'] !== undefined) {
                form['note'] = parameters['note'];
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postWazeOpenURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeOpen';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#postWazeOpen
     * @param {string} id - 
     */
    postWazeOpen(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeOpen';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postWazeCloseURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeClose';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#postWazeClose
     * @param {string} id - 
     */
    postWazeClose(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/wazeClose';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getRomoHistoryURL(parameters: {
        'surfaceDamageId': number,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/romoHistory';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['surfaceDamageId'] !== undefined) {
            queryParameters['surface-damage-id'] = this.convertParameterCollectionFormat(
                parameters['surfaceDamageId'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name ViewerApiClient#getRomoHistory
     * @param {number} surfaceDamageId - 
     */
    getRomoHistory(parameters: {
        'surfaceDamageId': number,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getRomoHistory_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/romoHistory';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['surfaceDamageId'] !== undefined) {
                queryParameters['surface-damage-id'] = this.convertParameterCollectionFormat(
                    parameters['surfaceDamageId'],
                    ''
                );
            }

            if (parameters['surfaceDamageId'] === undefined) {
                reject(new Error('Missing required  parameter: surfaceDamageId'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

}

export default ViewerApiClient;