import React, {useMemo} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../../../components/DateTimeFormatted';
import {Point} from 'geojson';

const DialogIncidentManagementClusteredSubIncident: React.FunctionComponent<Props> = ({properties}) => {
    const coordinates =
        useMemo(() =>
            ([...(properties.geometry as unknown as Point).coordinates]).reverse().join(', '), [properties]);

    return <table>
        <tbody>
            <tr>
                <th>ID</th>
                <td>{properties.id}</td>
            </tr>
            <tr>
                <th>Type</th>
                <td>{properties.incidentType}</td>
            </tr>
            <tr>
                <th>Data bron</th>
                <td>{properties.imSupplier}</td>
            </tr>
            <tr>
                <th>Aanmeld tijd</th>
                <td>
                    <DateTimeFormatted dateTime={properties.observationTime}/>
                </td>
            </tr>
            <tr>
                <th>Laatste update tijd</th>
                <td>
                    <DateTimeFormatted dateTime={properties.lastUpdatedTime}/>
                </td>
            </tr>
            <tr>
                <th>Start tijd</th>
                <td>
                    <DateTimeFormatted dateTime={properties.overallStartTime}/>
                </td>
            </tr>
            <tr>
                <th>Staat het er nog?</th>
                <td>
                    {properties.levelOfNonPersistence}
                </td>
            </tr>
            <tr>
                <th>Bepalingsmethode</th>
                <td>
                    {properties.levelOfNonPersistenceDetType}
                </td>
            </tr>
            <tr>
                <th>Kans op gebeurtenis</th>
                <td>
                    {properties.levelOfPersistence}
                </td>
            </tr>
            <tr>
                <th>Bepalingsmethode gebeurtenis</th>
                <td>
                    {properties.persistenceDeterminationType}
                </td>
            </tr>
            <tr>
                <th>Incident Beveiligd</th>
                <td>
                    {properties.incidentProtected ? 'Ja':'Nee'}
                </td>
            </tr>
            <tr>
                <th>Incident bekend bij VC</th>
                <td>
                    {properties.incidentKnownByTmc ? 'Ja':'Nee'}
                </td>
            </tr>
            <tr>
                <th>Incident bekend bij VC sinds</th>
                <td>
                    <DateTimeFormatted dateTime={properties.incidentKnownByTmcSince}/>
                </td>
            </tr>
            <tr>

                <th>Coördinaten</th>
                <td>{coordinates}</td>
            </tr>
        </tbody>
    </table>;
};

export default DialogIncidentManagementClusteredSubIncident;
