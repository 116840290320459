import {combineEpics, Epic} from 'redux-observable';
import {delay, filter, flatMap, ignoreElements, map, mergeMap, switchMap, takeUntil, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    changeWazeAlertIncidentStatusValue,
    closeCurrentDialog,
    closeWazeDialogAfterTimeout,
    openNewDialog,
    openNewWazeDialog,
    setNewWazeAlertSavingStatus,
    updateWazeAlertDialogTimeRemaining,
    updateWazeAlertIncidentDialogStatusData
} from '../../../scenes/MapScene/actions/reducers/activeDialogs';
import {loadWazeStatus, setWazeOpen, updateWazeStatus, updateWazeStatusClose} from '../../../services/ViewerApiService';
import {
    changeWazeAlertData,
    closeWazeAlertDialog,
    openWazeAlertDialog,
    saveWazeAlertData
} from '../../../scenes/MapScene/actions/wazeAlertIncidentDialog';
import {toast} from 'react-toastify';
import {viewerApplicationSceneChanged} from '../../../actions';
import {MAP_SCENE} from '../../../scenes';
import {interval, of} from 'rxjs';
import {cloneDeep} from 'lodash';
import {
    viewerApiServiceReceivedWazeStatusData,
    viewerApiServiceReceivingWazeStatusDataFailedWithBadRequestError,
    viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError,
    viewerApiServiceReceivingWazeStatusDataFailedWithUnexpectedError,
    viewerApiServiceSuccessFullyUpdatedWazeStatusData,
    viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError,
    viewerApiServiceUpdatingWazeStatusUnexpectedError
} from '../../../services/ViewerApiService/actions/wazeStatusData';
import {keycloakService} from '@ndw/react-keycloak-authentication';
import {DialogTypes, GeoJsonSubTypes, SavingStatus} from '../../../interfaces/ActiveDialog';
import {FrontOfficeEventStatus} from '../../../constants';
import {CustomEvent} from '@piwikpro/react-piwik-pro';

const changeWazeAlertIncidentStatusValueOnChangeWazeAlertData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(changeWazeAlertData)),
        map(({payload: {id, status, hectometrePost, type, subType}}) =>
            changeWazeAlertIncidentStatusValue(id as string, status, hectometrePost, type, subType))
    );

const closeCurrentDialogOnCloseWazeDialogAfterTimeout: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(closeWazeDialogAfterTimeout)),
        map((action) => closeCurrentDialog(action.payload.id))
    );

const openNewDialogOnOpenNewWazeDialog: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(openNewWazeDialog)),
        map(({payload: {dialog}}) => openNewDialog(dialog))
    );

const loadWazeStatusOnOpenNewWazeDialog: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(openNewDialog)),
        filter(({payload: {dialog}}) => dialog.type === DialogTypes.GEO_JSON && dialog.subType === GeoJsonSubTypes.WAZE_ALERT_INCIDENT),
        switchMap(({payload: {dialog: {id}}}) => keycloakService.token()
            .pipe(
                mergeMap(() => loadWazeStatus(id as string))
            )
        )
    );

const setWazeOpenOnOpenNewWazeDialog: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(openNewDialog)),
        filter(({payload: {dialog}}) => dialog.type === DialogTypes.GEO_JSON && dialog.subType === GeoJsonSubTypes.WAZE_ALERT_INCIDENT),
        switchMap(({payload: {dialog: {id}}}) => keycloakService.token()
            .pipe(
                mergeMap(() => setWazeOpen(id as string))
            )
        )
    );

const setNewWazeAlertSavingStatusOnSaveWazeAlertData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(saveWazeAlertData)),
        map(({payload: {id}}) => setNewWazeAlertSavingStatus(id as string, SavingStatus.callingServer))
    );

const setNewWazeAlertSavingStatusOnErrorWithUpdatingWazeData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError,
            viewerApiServiceUpdatingWazeStatusUnexpectedError
        ])),
        map(({payload: {id}}) => setNewWazeAlertSavingStatus(id, SavingStatus.savingFailed))
    );

const setNewWazeAlertSavingStatusOnSuccessFullyUpdatedWazeStatusData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceSuccessFullyUpdatedWazeStatusData)),
        map(({payload: {id}}) => setNewWazeAlertSavingStatus(id, SavingStatus.changesSaved))
    );

const showToastOnSuccessFullyUpdatedWazeStatusData: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApiServiceSuccessFullyUpdatedWazeStatusData)),
        tap(({payload: {name}}) => {
            toast(`De status van de Waze melding op ${name} is bijgewerkt.`, {
                type: 'success'
            });
        }),
        ignoreElements()
    );

const showToastMessageOnFailingToSaveWazeStatusData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            viewerApiServiceUpdatingWazeStatusUnexpectedError,
            viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError
        ])),
        tap(({payload: {name}}) => {
            toast(`De status van de Waze melding op ${name} kan niet worden bijgewerkt`, {
                type: 'error'
            });
        }),
        ignoreElements()
    );

const closeWazeDialogOnSuccessFullyUpdatedWazeStatusData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceSuccessFullyUpdatedWazeStatusData)),
        delay(500),
        map(({payload: {id}}) => closeCurrentDialog(id))
    );

const showToastOnCloseWazeDialogAfterTimeout: Epic = (action$) =>
    action$
        .pipe(
            filter(isActionOf(closeWazeDialogAfterTimeout)),
            tap(() => {
                toast('De Waze melding is gesloten en weer vrijgegeven wegen inactiviteit.', {
                    type: 'warning'
                });
            }),
            ignoreElements()
        );

const updateWazeAlertIncidentDialogStatusDataOnReceivedWazeStatusData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedWazeStatusData)),
        map(({payload: {id, wazeStatusData}}) => updateWazeAlertIncidentDialogStatusData(
            id,
            true,
            false,
            wazeStatusData.status as FrontOfficeEventStatus,
            wazeStatusData.open!,
            wazeStatusData.location
        ))
    );

const updateWazeAlertIncidentDialogStatusDataOnErrorReceivingWazeStatusData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            viewerApiServiceReceivingWazeStatusDataFailedWithBadRequestError,
            viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError,
            viewerApiServiceReceivingWazeStatusDataFailedWithUnexpectedError
        ])),
        map(({payload: {id}}) => updateWazeAlertIncidentDialogStatusData(
            id,
            true,
            true,
            null,
            false,
            null
        ))
    );

const updateWazeStatusOnSaveWazeAlertData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(saveWazeAlertData)),
        mergeMap(({payload: {id, name, status, hectometrePost, type, subType}}) =>
            updateWazeStatus(id as string, name, status, hectometrePost, type, subType))
    );

const sendCustomMetricPiPiWikOnSaveWazeAlertData: Epic = (actioN$) => actioN$
    .pipe(
        filter(isActionOf(saveWazeAlertData)),
        tap((action) => CustomEvent.trackEvent(
            'front_office',
            'save_item',
            typeof action.payload.id === 'number' ? action.payload.id.toString() : action.payload.id
        )),
        ignoreElements()
    );

const updateWazeStatusCloseOnCloseWazeAlertDialog: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(closeWazeAlertDialog)),
        mergeMap(({payload: {id}}) => updateWazeStatusClose(id))
    );

const reloadIntervalForWazeAlertDialogTimeout: Epic = (action$) => {
    const openWazeDialogs: { [key: string]: number } = {};
    const initialValue = 15 * 60;

    action$
        .pipe(filter(isActionOf(openWazeAlertDialog)))
        .subscribe((action) => {
            openWazeDialogs[action.payload.id] = initialValue;
        });

    action$
        .pipe(filter(isActionOf(closeWazeAlertDialog)))
        .subscribe((action) => {
            delete openWazeDialogs[action.payload.id];
        });

    action$
        .pipe(filter(isActionOf(viewerApiServiceReceivedWazeStatusData)))
        .subscribe(({payload: {id, wazeStatusData}}) => {
            if (openWazeDialogs.hasOwnProperty(id)) {
                if (wazeStatusData.open) {
                    delete openWazeDialogs[id];
                }
            }
        });

    return action$.pipe(
        filter(isActionOf(viewerApplicationSceneChanged)),
        filter((action) => action.payload.scene === MAP_SCENE),
        switchMap(() => interval(1000)
            .pipe(
                takeUntil(action$.pipe(
                    filter(isActionOf(viewerApplicationSceneChanged)),
                    filter((action) => action.payload.scene !== MAP_SCENE)
                )),
                filter(() => Object.keys(openWazeDialogs).length > 0),
                flatMap(() => {
                    return of(...Object.keys(openWazeDialogs))
                        .pipe(
                            map((wazeDialogID: string) => {
                                const newValue = openWazeDialogs[wazeDialogID] = cloneDeep(openWazeDialogs[wazeDialogID]) - 1;

                                if (newValue > 0) {
                                    return updateWazeAlertDialogTimeRemaining(wazeDialogID, newValue);
                                }

                                return closeWazeDialogAfterTimeout(wazeDialogID);
                            })
                        );
                })
            ))
    );
};

const wazeEpics: Epic = combineEpics(
    changeWazeAlertIncidentStatusValueOnChangeWazeAlertData,
    closeCurrentDialogOnCloseWazeDialogAfterTimeout,
    openNewDialogOnOpenNewWazeDialog,
    loadWazeStatusOnOpenNewWazeDialog,
    setWazeOpenOnOpenNewWazeDialog,
    setNewWazeAlertSavingStatusOnSaveWazeAlertData,
    setNewWazeAlertSavingStatusOnSuccessFullyUpdatedWazeStatusData,
    setNewWazeAlertSavingStatusOnErrorWithUpdatingWazeData,
    showToastOnSuccessFullyUpdatedWazeStatusData,
    showToastOnCloseWazeDialogAfterTimeout,
    updateWazeAlertIncidentDialogStatusDataOnReceivedWazeStatusData,
    updateWazeAlertIncidentDialogStatusDataOnErrorReceivingWazeStatusData,
    updateWazeStatusOnSaveWazeAlertData,
    updateWazeStatusCloseOnCloseWazeAlertDialog,
    reloadIntervalForWazeAlertDialogTimeout,
    closeWazeDialogOnSuccessFullyUpdatedWazeStatusData,
    showToastMessageOnFailingToSaveWazeStatusData,
    sendCustomMetricPiPiWikOnSaveWazeAlertData
);

export default wazeEpics;
