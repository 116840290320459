import React from 'react';
import {Props} from './Props';
import BearingCompass from '../../../../../../components/BearingCompass';
import {format} from 'date-fns';
import {MultiChartDataSet, LineChart} from 'react-chartkick';
import {useDispatch} from 'react-redux';
import {loadSurfaceDamagesHistory} from '../../../../actions/romoSurfaceDamagesDialog';
import {roadOperatorTypeLabels} from '../../../../../../constants';

export const DialogRomoSurfaceDamages: React.FunctionComponent<Props> = ({history, properties, showRecordIds}) => {
    const dispatch = useDispatch();
    if (history === null || history === undefined) {
        dispatch(loadSurfaceDamagesHistory(properties.id));
    }

    const clusterSize = !properties.clusterSizeDelta ? properties.clusterSize : `${properties.clusterSize} (+ ${properties.clusterSizeDelta} sinds laatste update)`;
    const clusterJerk = !properties.clusterJerkDelta ? properties.clusterJerk : `${properties.clusterJerk} (+ ${properties.clusterJerkDelta} sinds laatste update)`;
    const recentEvent = format(new Date(properties.recentEvent), 'dd-MM-yyyy');
    const historyChartData: MultiChartDataSet[] | undefined = history && [{
        data: history?.reduce((prev, curr) => ({...prev, [curr.recentEvent]: curr.clusterSize}), {}),
        fill: true,
        name: 'Grootte'
    }, {
        data: history?.reduce((prev, curr) => ({...prev, [curr.recentEvent]: curr.clusterJerk}), {}),
        fill: true,
        name: 'Schok'
    }];
    return <>
        {properties.id && <table>
            <tbody>
                {showRecordIds && <tr>
                    <th>ID</th>
                    <td>{properties.id}</td>
                </tr>}
                <tr>
                    <th>Grootte</th>
                    <td>{clusterSize}</td>
                </tr>
                <tr>
                    <th>Schok</th>
                    <td>{clusterJerk}</td>
                </tr>
                <tr>
                    <th>Richting</th>
                    <td><BearingCompass bearing={properties.heading}></BearingCompass></td>
                </tr>
                <tr>
                    <th>Wegbeheerder</th>
                    <td>
                        {properties.roadOperatorName} {properties.roadOperatorType && (
                            <span>
                                ({roadOperatorTypeLabels[properties.roadOperatorType]})
                            </span>
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Laatst gewijzigd op</th>
                    <td>{recentEvent}</td>
                </tr>
            </tbody>
        </table>}
        <br/>
        {historyChartData && <LineChart data={historyChartData} />}
    </>;
};
