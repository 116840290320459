import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import React from 'react';
import {Point} from 'geojson';

export const DialogIncidentManagementUnclustered: React.FunctionComponent<Props> = (props) => {
    // reversing array due to issue in viewer common where coordinates are reversed
    const coordinates = (JSON.parse(props.properties.geometry) as Point).coordinates.reverse().join(', ');
    return <>
        <table>
            <tbody>
                {props.showRecordIds && <tr>
                    <th>ID</th>
                    <td>{props.properties.id}</td>
                </tr>}
                <tr>
                    <th>Type</th>
                    <td>{props.properties.incidentType}</td>
                </tr>
                <tr>
                    <th>Data bron</th>
                    <td>{props.properties.imSupplier}</td>
                </tr>
                <tr>
                    <th>Aanmeld tijd</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.observationTime}/>
                    </td>
                </tr>
                <tr>
                    <th>Laatste update tijd</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.lastUpdatedTime}/>
                    </td>
                </tr>
                <tr>
                    <th>Start tijd</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.overallStartTime}/>
                    </td>
                </tr>
                <tr>
                    <th>Coordinaten</th>
                    <td>{coordinates}</td>
                </tr>
                <tr>
                    <th>Staat het er nog?</th>
                    <td>
                        {props.properties.levelOfNonPersistence}
                    </td>
                </tr>
                <tr>
                    <th>Bepalingsmethode</th>
                    <td>
                        {props.properties.levelOfNonPersistenceDetType}
                    </td>
                </tr>
                <tr>
                    <th>Kans op gebeurtenis</th>
                    <td>
                        {props.properties.levelOfPersistence}
                    </td>
                </tr>
                <tr>
                    <th>Bepalingsmethode gebeurtenis</th>
                    <td>
                        {props.properties.persistenceDeterminationType}
                    </td>
                </tr>
                <tr>
                    <th>Incident Beveiligd</th>
                    <td>
                        {props.properties.incidentProtected ? 'Ja':'Nee'}
                    </td>
                </tr>
                <tr>
                    <th>Incident bekend bij VC</th>
                    <td>
                        {props.properties.incidentKnownByTmc ? 'Ja':'Nee'}
                    </td>
                </tr>
                <tr>
                    <th>Incident bekend bij VC sinds</th>
                    <td>
                        <DateTimeFormatted dateTime={props.properties.incidentKnownByTmcSince}/>
                    </td>
                </tr>
            </tbody>
        </table>
    </>;
};
