/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SnapshotControllerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public getSnapshotById(
        id: string,
    ): CancelablePromise<{
        cancelled?: boolean;
        done?: boolean;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/snapshots/{id}',
            path: {
                'id': id,
            },
        });
    }

}
