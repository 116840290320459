import {ActionType, createAction} from 'typesafe-actions';
import {ReleaseNoteDTO} from '../../../../generated/ViewerApiClient';

export const viewerApiServiceReceivedReleaseNotesData = createAction(
    'VIEWER_API_SERVICE_RECEIVED_RELEASE_NOTES_DATA',
    (action) => (data: ReleaseNoteDTO[]) => action({data})
);

export const viewerApiServiceLoadingReleaseNotesUnexpectedError = createAction(
    'VIEWER_API_SERVICE_LOADING_RELEASE_NOTES_UNEXPECTED_ERROR',
    (action) => () => action({})
);

export type ReleaseNotesDataActionTypes = ActionType<typeof viewerApiServiceReceivedReleaseNotesData> |
    ActionType<typeof viewerApiServiceLoadingReleaseNotesUnexpectedError>;
