import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {keycloakInstanceCreated, keycloakService, setKeycloakAuthenticationInDevelopmentMode} from '@ndw/react-keycloak-authentication';
import {loadTrafficCentersData} from '../../services/ViewerApiService';
import {viewerApiServiceReceivedTrafficCentersData} from '../../services/ViewerApiService/actions/trafficCentersData';
import {setNewTrafficCenters} from '../../modules/TrafficCenterReducer/actions';
import {RootState} from '../../reducer';
import {changeCurrentTrafficCenter} from '../../modules/UserSettingsReducer/actions';
import {TrafficCenterDTO} from '../../generated/ViewerApiClient';
import UrlService from '../../services/UrlService';

const loadTrafficCentersOnKeycloakStarted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            keycloakInstanceCreated,
            setKeycloakAuthenticationInDevelopmentMode
        ])),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => loadTrafficCentersData())
            ))
    );

const setCurrentTrafficCenterOnReceivedTrafficCentersData: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedTrafficCentersData)),
        filter(() => !state$.value.trafficCenter.trafficCentersLoaded),
        map(({payload: {data}}) => {
            let trafficCenter: TrafficCenterDTO | null = null;
            const trafficCenterUrlValue = UrlService.getInstance().getStringValueFromUrl('trafficCenter', '');
            if (trafficCenterUrlValue.length > 0) {
                data.forEach((item: TrafficCenterDTO) => {
                    if (item.key === trafficCenterUrlValue) {
                        trafficCenter = item;
                    }
                });
            }
            return changeCurrentTrafficCenter(trafficCenter);
        })
    );

const setNewTrafficCentersOnReceivedTrafficCentersData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedTrafficCentersData)),
        map(({payload: {data}}) => setNewTrafficCenters(data))
    );

const trafficCenterEpics: Epic = combineEpics(
    loadTrafficCentersOnKeycloakStarted,
    setCurrentTrafficCenterOnReceivedTrafficCentersData,
    setNewTrafficCentersOnReceivedTrafficCentersData
);

export default trafficCenterEpics;
